<template>
  <svg
    width="892"
    height="868"
    viewBox="0 0 892 868"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="undraw_designer_re_5v95 1" clip-path="url(#clip0_3_4164)">
      <path
        id="Vector"
        d="M839.202 239.571C830.851 224.487 821.586 209.929 811.458 195.979C804.362 186.192 796.863 176.731 788.963 167.597H788.943C774.648 151.062 759.101 135.654 742.441 121.511C739.031 118.612 735.575 115.772 732.073 112.991C700.461 87.8106 665.405 67.301 627.977 52.0889L628.309 106.852L631.89 731.116H321.466L304.008 108.029L305.309 54.1413C153.117 118.23 46.2565 268.914 46.2565 444.597C46.2281 480.859 50.8486 516.975 60.0047 552.059C63.872 566.89 68.5541 581.495 74.0296 595.809C76.0063 601.005 78.0917 606.154 80.2858 611.257C81.3434 613.741 82.4207 616.205 83.5376 618.659C84.9707 621.865 86.4631 625.062 87.9951 628.219C89.1811 630.703 90.4001 633.17 91.6521 635.621C93.2631 638.837 94.9268 642.027 96.6433 645.19C97.9677 647.674 99.3317 650.138 100.715 652.593C123.039 692.129 151.546 727.828 185.151 758.332C194.729 767.031 204.695 775.277 215.049 783.072C288.316 838.195 377.482 868 469.128 868C560.774 868 649.94 838.195 723.207 783.072C728.841 778.837 734.359 774.459 739.762 769.94C743.35 766.951 746.878 763.907 750.348 760.806C751.267 759.985 752.186 759.163 753.095 758.332C784.078 730.21 810.742 697.661 832.224 661.737C840.113 648.566 847.266 634.968 853.651 621.004C855.341 617.323 856.979 613.609 858.564 609.862C865.857 592.681 871.993 575.029 876.927 557.026C877.688 554.246 878.423 551.455 879.131 548.655V548.645C879.25 548.16 879.378 547.665 879.497 547.18C880.229 544.231 880.93 541.262 881.602 538.293V538.284C883.777 528.714 885.612 519.029 887.107 509.229C887.295 507.972 887.483 506.715 887.661 505.458C887.73 505.003 887.799 504.548 887.849 504.093V504.083C888.916 496.512 889.783 488.886 890.448 481.203C891.476 469.14 891.993 456.938 892 444.598C892.112 372.87 873.939 302.301 839.202 239.571Z"
        fill="#F2F2F2"
      />
      <path
        id="Vector_2"
        d="M797.673 700.182C822.702 666.32 832.328 618.624 812.859 581.279C794.599 623.285 762.088 657.469 721.079 677.785C705.235 685.563 686.804 692.561 679.471 708.631C674.907 718.63 675.776 730.436 679.445 740.798C683.113 751.159 689.353 760.379 695.526 769.47L695.851 772.195C734.717 756.06 772.644 734.044 797.673 700.182Z"
        fill="#E6E6E6"
      />
      <path
        id="Vector_3"
        d="M812.028 581.579C810.906 616.43 798.846 650.041 777.56 677.638C773.083 683.661 767.685 688.939 761.567 693.279C755.47 697.313 748.405 699.64 741.107 700.016C734.309 700.504 727.299 700.214 720.738 702.348C717.395 703.491 714.332 705.327 711.748 707.738C709.165 710.149 707.119 713.079 705.746 716.337C702.113 724.365 701.578 733.222 701.356 741.901C701.109 751.537 701.005 761.551 696.465 770.311C695.915 771.372 697.592 772.165 698.141 771.105C706.04 755.864 700.911 738.041 705.618 722.019C707.814 714.543 712.274 707.684 719.728 704.689C726.247 702.069 733.476 702.361 740.363 701.927C747.488 701.664 754.441 699.659 760.614 696.085C766.933 692.072 772.524 687.01 777.148 681.118C787.769 668.019 796.266 653.33 802.33 637.589C809.349 619.661 813.254 600.663 813.876 581.418C813.913 580.226 812.066 580.396 812.028 581.579Z"
        fill="white"
      />
      <path
        id="Vector_4"
        d="M784.936 669.156C790.045 672.103 796.035 673.136 801.834 672.07C807.634 671.004 812.866 667.909 816.597 663.337C817.348 662.409 815.944 661.196 815.191 662.125C811.725 666.389 806.851 669.272 801.448 670.253C796.046 671.235 790.471 670.25 785.729 667.478C784.698 666.876 783.911 668.558 784.936 669.156Z"
        fill="white"
      />
      <path
        id="Vector_5"
        d="M744.586 700.223C741.352 688.597 742.613 676.177 748.118 665.441C748.664 664.377 746.987 663.584 746.442 664.646C740.734 675.83 739.448 688.757 742.84 700.848C743.164 702 744.909 701.369 744.586 700.223Z"
        fill="white"
      />
      <path
        id="Vector_6"
        d="M806.892 619.747C804.678 619.019 802.679 617.752 801.075 616.06C799.471 614.367 798.311 612.302 797.701 610.05C797.392 608.894 795.648 609.527 795.955 610.675C796.641 613.16 797.924 615.439 799.692 617.313C801.46 619.187 803.66 620.599 806.099 621.425C806.322 621.523 806.574 621.531 806.804 621.449C807.033 621.367 807.222 621.2 807.334 620.983C807.438 620.761 807.449 620.506 807.367 620.274C807.284 620.043 807.114 619.853 806.892 619.747Z"
        fill="white"
      />
      <path
        id="Vector_7"
        d="M673.531 551.56C673.704 552.287 673.876 553.014 674.039 553.753C676.31 563.542 677.783 573.499 678.444 583.527C678.507 584.301 678.56 585.087 678.601 585.862C679.935 610.037 676.775 634.25 669.281 657.27C666.291 666.428 662.609 675.344 658.268 683.942C652.272 695.817 644.826 708.214 643.147 721.001C642.957 722.334 642.85 723.676 642.825 725.022L692.506 772.381C692.673 772.432 692.829 772.494 692.997 772.546L694.894 774.528C695.149 774.1 695.404 773.651 695.659 773.223C695.808 772.975 695.945 772.717 696.094 772.47C696.19 772.301 696.285 772.132 696.381 771.986C696.413 771.93 696.445 771.873 696.466 771.829C696.562 771.682 696.636 771.536 696.721 771.401C698.154 768.894 699.572 766.373 700.975 763.837C700.985 763.826 700.985 763.826 700.985 763.804C711.625 744.456 720.744 724.048 726.48 702.904C726.653 702.268 726.836 701.621 726.986 700.963C729.519 691.417 731.268 681.681 732.217 671.85C732.719 666.465 732.948 661.059 732.904 655.651C732.764 641.678 730.429 627.814 725.986 614.567C717.125 588.162 699.927 564.888 675.413 552.489C674.786 552.172 674.169 551.865 673.531 551.56Z"
        fill="#E6E6E6"
      />
      <path
        id="Vector_8"
        d="M673.042 552.298C693.103 580.8 703.685 614.907 703.283 649.774C703.33 657.282 702.195 664.749 699.919 671.903C697.477 678.8 693.235 684.916 687.634 689.616C682.499 694.103 676.728 698.098 672.773 703.757C670.791 706.684 669.449 709.997 668.836 713.48C668.223 716.962 668.352 720.535 669.214 723.964C671.141 732.564 676.04 739.958 681.081 747.022C686.678 754.865 692.616 762.923 694.259 772.654C694.458 773.833 696.274 773.454 696.075 772.277C693.217 755.347 678.405 744.208 672.529 728.578C669.787 721.285 669.224 713.12 673.374 706.234C677.003 700.213 682.951 696.089 688.189 691.59C693.72 687.085 698.065 681.293 700.845 674.719C703.478 667.704 704.899 660.292 705.047 652.801C705.651 635.94 703.603 619.089 698.978 602.865C693.803 584.319 685.497 566.796 674.421 551.055C673.735 550.081 672.361 551.33 673.042 552.298Z"
        fill="white"
      />
      <path
        id="Vector_9"
        d="M704.073 638.555C709.924 637.828 715.327 635.042 719.317 630.695C723.307 626.348 725.623 620.722 725.853 614.823C725.895 613.629 724.044 613.507 724.002 614.702C723.798 620.196 721.64 625.436 717.916 629.477C714.193 633.517 709.15 636.092 703.697 636.736C702.512 636.878 702.895 638.695 704.073 638.555Z"
        fill="white"
      />
      <path
        id="Vector_10"
        d="M690.537 687.684C680.963 680.351 674.502 669.674 672.441 657.783C672.238 656.605 670.422 656.983 670.625 658.16C672.793 670.53 679.539 681.627 689.519 689.236C690.47 689.96 691.483 688.405 690.537 687.684Z"
        fill="white"
      />
      <path
        id="Vector_11"
        d="M691.893 585.869C689.687 586.622 687.329 586.816 685.031 586.431C682.732 586.047 680.565 585.097 678.723 583.667C677.781 582.93 676.769 584.487 677.705 585.218C679.747 586.789 682.142 587.835 684.68 588.266C687.219 588.696 689.824 588.497 692.269 587.687C692.506 587.63 692.712 587.485 692.845 587.281C692.979 587.077 693.03 586.83 692.989 586.59C692.938 586.349 692.794 586.139 692.589 586.004C692.384 585.869 692.133 585.82 691.893 585.869Z"
        fill="white"
      />
      <path
        id="Vector_12"
        d="M19.7413 510.531C-2.45693 474.744 -8.17976 426.422 14.2568 390.786C29.0467 434.138 58.6749 470.855 97.8994 494.44C113.06 503.481 130.862 511.957 136.867 528.57C140.603 538.907 138.779 550.604 134.282 560.632C129.784 570.661 122.817 579.343 115.926 587.901L115.381 590.591C77.9535 571.347 41.9395 546.317 19.7413 510.531Z"
        fill="#E6E6E6"
      />
      <path
        id="Vector_13"
        d="M15.0602 391.152C13.3492 425.979 22.6416 460.46 41.6175 489.698C45.5909 496.065 50.5419 501.765 56.2881 506.589C62.0373 511.106 68.8897 513.999 76.1334 514.968C82.8694 516.008 89.8797 516.29 96.246 518.95C99.4846 520.361 102.389 522.441 104.768 525.054C107.148 527.667 108.949 530.754 110.053 534.113C113.022 542.41 112.836 551.281 112.353 559.949C111.817 569.574 111.108 579.563 114.922 588.663C115.384 589.766 113.648 590.419 113.187 589.318C106.552 573.485 113.11 556.138 109.719 539.786C108.137 532.156 104.249 524.958 97.0622 521.365C90.778 518.223 83.5491 517.926 76.7197 516.933C69.6394 516.092 62.8726 513.527 57.0103 509.463C51.0374 504.949 45.8754 499.449 41.7453 493.2C32.223 479.281 24.9458 463.949 20.1802 447.766C14.639 429.326 12.289 410.074 13.2318 390.841C13.2906 389.651 15.1186 389.97 15.0602 391.152Z"
        fill="white"
      />
      <path
        id="Vector_14"
        d="M34.954 480.644C29.6225 483.166 23.5692 483.708 17.8754 482.174C12.1815 480.639 7.21769 477.128 3.87049 472.268C3.19659 471.282 4.69488 470.187 5.36968 471.174C8.47824 475.706 13.1023 478.976 18.4074 480.394C23.7125 481.812 29.349 481.284 34.2998 478.907C35.3762 478.391 36.0243 480.132 34.954 480.644Z"
        fill="white"
      />
      <path
        id="Vector_15"
        d="M72.6487 514.892C76.816 503.567 76.5673 491.086 71.952 479.937C71.4939 478.833 73.2295 478.179 73.6871 479.282C78.4683 490.893 78.7008 503.882 74.3382 515.657C73.9216 516.779 72.2344 516.008 72.6487 514.892Z"
        fill="white"
      />
      <path
        id="Vector_16"
        d="M17.0811 429.612C19.3471 429.067 21.4423 427.966 23.1786 426.41C24.9149 424.853 26.2379 422.89 27.0288 420.695C27.431 419.568 29.1179 420.34 28.7183 421.46C27.8332 423.881 26.3695 426.048 24.4549 427.772C22.5404 429.496 20.2334 430.724 17.7354 431.349C17.5049 431.428 17.2531 431.416 17.0312 431.315C16.8093 431.215 16.6339 431.033 16.5407 430.808C16.4552 430.578 16.4641 430.323 16.5652 430.099C16.6664 429.875 16.8518 429.7 17.0811 429.612Z"
        fill="white"
      />
      <path
        id="Vector_17"
        d="M155.536 372.502C155.304 373.213 155.073 373.923 154.851 374.647C151.793 384.218 149.517 394.023 148.045 403.963C147.919 404.73 147.802 405.509 147.699 406.277C144.407 430.264 145.591 454.654 151.191 478.208C153.428 487.579 156.374 496.765 160.003 505.689C165.016 518.011 171.43 530.973 172.066 543.855C172.147 545.198 172.145 546.545 172.06 547.888L118.701 591.048C118.53 591.085 118.369 591.135 118.198 591.173L116.146 592.994C115.927 592.546 115.709 592.078 115.49 591.631C115.361 591.372 115.245 591.104 115.117 590.845C115.035 590.669 114.954 590.493 114.87 590.339C114.842 590.281 114.816 590.222 114.798 590.176C114.714 590.022 114.653 589.87 114.579 589.729C113.354 587.114 112.145 584.485 110.953 581.844C110.943 581.832 110.943 581.832 110.945 581.81C101.911 561.66 94.4784 540.578 90.4774 519.037C90.3573 518.389 90.2267 517.729 90.1309 517.061C88.3812 507.341 87.428 497.494 87.2805 487.619C87.2172 482.211 87.4273 476.804 87.9101 471.417C89.1841 457.502 92.6363 443.874 98.1401 431.033C109.115 405.436 128.145 383.638 153.584 373.275C154.235 373.01 154.875 372.754 155.536 372.502Z"
        fill="#E6E6E6"
      />
      <path
        id="Vector_18"
        d="M155.963 373.277C133.655 400.053 120.34 433.186 117.91 467.97C117.254 475.449 117.78 482.985 119.467 490.3C121.341 497.372 125.073 503.814 130.274 508.954C135.027 513.844 140.455 518.296 143.938 524.258C145.676 527.336 146.744 530.748 147.073 534.268C147.401 537.789 146.983 541.34 145.845 544.687C143.226 553.102 137.744 560.073 132.146 566.703C125.93 574.065 119.358 581.613 116.931 591.178C116.637 592.337 114.858 591.812 115.151 590.655C119.374 574.013 135.042 564.116 142.167 549.016C145.491 541.97 146.716 533.878 143.138 526.678C140.009 520.381 134.416 515.786 129.56 510.876C124.413 505.936 120.552 499.809 118.315 493.031C116.261 485.825 115.446 478.322 115.906 470.843C116.673 453.989 120.082 437.36 126.008 421.566C132.672 403.503 142.372 386.714 154.69 371.927C155.453 371.012 156.72 372.368 155.963 373.277Z"
        fill="white"
      />
      <path
        id="Vector_19"
        d="M118.034 456.724C112.261 455.523 107.101 452.307 103.478 447.649C99.8542 442.992 98.002 437.196 98.2521 431.298C98.2645 431.057 98.3697 430.831 98.5454 430.667C98.7212 430.502 98.9538 430.413 99.1942 430.417C99.4346 430.421 99.6642 430.518 99.8344 430.688C100.005 430.858 100.102 431.088 100.107 431.328C99.8637 436.821 101.589 442.219 104.973 446.549C108.356 450.879 113.173 453.856 118.556 454.942C119.726 455.179 119.197 456.96 118.034 456.724Z"
        fill="white"
      />
      <path
        id="Vector_20"
        d="M127.538 506.792C137.675 500.262 144.981 490.147 148 478.463C148.298 477.305 150.077 477.829 149.779 478.986C146.615 491.138 138.99 501.65 128.426 508.422C127.419 509.066 126.536 507.433 127.538 506.792Z"
        fill="white"
      />
      <path
        id="Vector_21"
        d="M134.45 405.204C136.587 406.134 138.921 406.519 141.244 406.323C143.566 406.126 145.803 405.356 147.754 404.08C148.753 403.423 149.636 405.057 148.643 405.71C146.481 407.109 144.009 407.957 141.444 408.179C138.879 408.402 136.298 407.992 133.927 406.985C133.696 406.909 133.502 406.747 133.386 406.533C133.269 406.319 133.238 406.069 133.299 405.833C133.369 405.597 133.53 405.399 133.745 405.281C133.961 405.163 134.214 405.135 134.45 405.204Z"
        fill="white"
      />
      <g id="clouds">
        <path
          id="cloud3"
          d="M431.81 145.234C464.257 145.234 490.561 118.897 490.561 86.4097C490.561 53.9221 464.257 27.5857 431.81 27.5857C399.363 27.5857 373.06 53.9221 373.06 86.4097C373.06 118.897 399.363 145.234 431.81 145.234Z"
          fill="#FF6584"
        />
        <path
          id="cloud2"
          d="M611.691 83.3401C606.397 77.7407 600.808 72.4288 594.948 67.4272C583.502 57.7094 570.832 49.4461 556.708 44.2408C551.39 42.2746 545.896 40.8264 540.301 39.9162C539.629 39.8074 538.967 39.6985 538.295 39.6094C538.245 39.6045 538.196 39.6045 538.146 39.6094C538.043 39.5801 537.937 39.5603 537.83 39.55C536.456 39.3719 535.082 39.2334 533.699 39.1344C526.247 38.6384 518.763 39.3129 511.52 41.1335C507.317 42.1951 503.216 43.6257 499.264 45.4085C495.305 47.3492 491.467 49.527 487.769 51.93C485.784 53.1376 483.739 54.244 481.642 55.2452C475.519 58.1498 469.038 60.2264 462.368 61.4204C457.865 62.2293 453.304 62.6794 448.729 62.7662C444.756 62.8454 441.01 62.9839 437.244 64.4188C433.225 65.9308 429.615 68.3631 426.703 71.5213C423.791 74.6796 421.657 78.4762 420.472 82.6078C420.378 82.9747 420.405 83.3619 420.547 83.7127C420.689 84.0635 420.94 84.3595 421.262 84.5573C422.28 85.2896 423.269 86.1209 424.346 86.774C425.297 87.2085 426.311 87.4858 427.351 87.5954C429.051 87.9517 430.754 88.298 432.461 88.6345C438.737 89.8814 445.039 90.9864 451.368 91.9497C464.098 93.8794 476.901 95.2385 489.776 96.0268C489.854 96.0383 489.934 96.045 490.013 96.0466C504.558 96.9256 519.139 97.0677 533.699 96.4722C535.903 96.3831 538.097 96.2742 540.301 96.1456C549.483 95.631 558.642 94.8195 567.777 93.7111C576.228 92.682 584.646 91.4054 593.03 89.8814C599.02 88.7928 604.986 87.5789 610.93 86.2396C611.218 86.1541 611.48 85.9955 611.689 85.7792C611.899 85.5628 612.049 85.296 612.126 85.0045C612.202 84.713 612.203 84.4067 612.126 84.1151C612.05 83.8236 611.9 83.5567 611.691 83.3401Z"
          fill="#E6E6E6"
        />
        <path
          id="cloud1"
          d="M489.578 161.776C481.073 152.666 471.702 144.407 461.598 137.115C458.247 134.76 454.798 132.543 451.25 130.514C446.899 128 442.382 125.788 437.729 123.894C436.691 123.468 435.643 123.063 434.596 122.677C428.644 120.47 422.47 118.917 416.182 118.045C416.133 118.036 416.084 118.045 416.034 118.036C415.935 118.016 415.827 117.996 415.728 117.976C412.68 117.58 409.609 117.392 406.536 117.412C404.332 117.412 402.118 117.531 399.924 117.749C396.379 118.098 392.864 118.707 389.408 119.569C387.698 119.995 385.998 120.49 384.327 121.054C381.891 121.857 379.499 122.788 377.162 123.845C377.122 123.864 377.093 123.874 377.053 123.894C373.129 125.816 369.327 127.978 365.667 130.366C365.588 130.416 365.499 130.465 365.42 130.514C361.263 133.004 356.866 135.066 352.295 136.67C348.371 138.044 344.346 139.109 340.256 139.856C335.753 140.667 331.192 141.114 326.617 141.192C322.644 141.272 318.898 141.41 315.142 142.855C311.122 144.366 307.511 146.799 304.597 149.957C301.683 153.115 299.547 156.912 298.36 161.044C298.268 161.411 298.295 161.797 298.437 162.148C298.579 162.498 298.829 162.794 299.15 162.993C300.168 163.726 301.167 164.547 302.234 165.2C303.183 165.638 304.199 165.915 305.239 166.021C306.939 166.378 308.639 166.734 310.348 167.07C316.625 168.317 322.93 169.419 329.266 170.376C337.953 171.692 346.677 172.744 355.438 173.533C359.589 173.919 363.743 174.235 367.901 174.483C378.565 175.126 389.24 175.377 399.924 175.235C402.128 175.205 404.332 175.156 406.536 175.086C419.622 174.7 432.668 173.717 445.675 172.137C460.173 170.381 474.573 167.887 488.817 164.666C489.106 164.581 489.367 164.424 489.576 164.208C489.786 163.993 489.936 163.727 490.013 163.436C490.089 163.145 490.089 162.84 490.013 162.549C489.937 162.258 489.787 161.992 489.578 161.776Z"
          fill="#E6E6E6"
        />
      </g>
      <path
        id="Vector_22"
        d="M343.138 3.90547L394.896 720.042L291.381 729.241V13.0167C303.716 3.36962 321.422 1.8603 343.138 3.90547Z"
        fill="#CCCCCC"
      />
      <path
        id="Vector_23"
        d="M598.623 3.90547L546.865 720.042L650.38 729.241V13.0167C638.046 3.36962 620.339 1.8603 598.623 3.90547Z"
        fill="#CCCCCC"
      />
      <path
        id="Vector_24"
        d="M652.816 13.3293C649.604 5.44385 642.774 0 634.877 0H305.781C294.801 0 285.876 10.5489 285.876 23.5278V735.309C285.78 741.894 288.239 748.259 292.735 753.066C295.087 755.54 298.066 757.328 301.354 758.241C302.796 758.64 304.285 758.841 305.781 758.837H634.877C636.373 758.841 637.863 758.64 639.305 758.241C648.161 755.858 654.783 746.489 654.783 735.309V23.5278C654.792 20.0329 654.124 16.5695 652.816 13.3293ZM648.18 735.309C648.18 743.977 642.211 751.033 634.877 751.033H305.781C299.703 751.033 294.554 746.185 292.982 739.584C292.646 738.184 292.477 736.749 292.478 735.309V23.5278C292.478 14.8597 298.448 7.81539 305.781 7.81539H634.877C636.227 7.81432 637.566 8.05567 638.831 8.52798C644.237 10.514 648.18 16.4835 648.18 23.5278V735.309Z"
        fill="#CCCCCC"
      />
      <path
        id="Vector_25"
        d="M651.482 216.357H289.178V222.972H651.482V216.357Z"
        fill="#CCCCCC"
      />
      <path
        id="Vector_26"
        d="M651.482 79.7913H289.178V86.4069H651.482V79.7913Z"
        fill="#CCCCCC"
      />
      <path
        id="Vector_27"
        d="M651.482 326.617H289.178V333.233H651.482V326.617Z"
        fill="#CCCCCC"
      />
      <path
        id="Vector_28"
        d="M406.749 2.96875H400.142V420.339H406.749V2.96875Z"
        fill="#CCCCCC"
      />
      <path
        id="Vector_29"
        d="M540.518 2.96875H533.911V420.339H540.518V2.96875Z"
        fill="#CCCCCC"
      />
      <path
        id="Vector_30"
        d="M692.775 717.491H258.893C251.011 717.5 243.455 720.639 237.881 726.22C232.308 731.8 229.173 739.366 229.163 747.258C229.159 751.053 229.888 754.813 231.308 758.332C233.515 763.851 237.322 768.582 242.239 771.915C247.155 775.249 252.956 777.032 258.893 777.035H692.775C697.966 777.034 703.065 775.672 707.566 773.085C712.067 770.498 715.814 766.777 718.433 762.29C719.179 761.022 719.827 759.699 720.371 758.332C721.791 754.813 722.519 751.053 722.515 747.258C722.504 739.365 719.366 731.799 713.791 726.219C708.216 720.639 700.659 717.5 692.775 717.491Z"
        fill="#CCCCCC"
      />
      <path
        id="Vector_31"
        d="M651.482 404.903H291.381V426.955H651.482V404.903Z"
        fill="#CCCCCC"
      />
      <path
        id="Vector_32"
        d="M468.127 398.287H474.735C477.947 398.287 481.029 399.565 483.3 401.839C485.572 404.114 486.848 407.199 486.848 410.416H456.014C456.014 408.823 456.327 407.246 456.936 405.774C457.545 404.303 458.437 402.966 459.562 401.839C460.687 400.713 462.022 399.82 463.492 399.21C464.961 398.601 466.537 398.287 468.127 398.287Z"
        fill="#CCCCCC"
      />
      <path
        id="Vector_33"
        d="M757.049 758.332C756.139 759.163 755.22 759.985 754.301 760.806C750.822 763.903 747.294 766.948 743.716 769.94C738.309 774.453 732.791 778.83 727.161 783.072H219.003C208.655 775.294 198.689 767.047 189.105 758.332H757.049Z"
        fill="#CCCCCC"
      />
      <transition appear @before-enter="beforeEnter" @enter="enter">
        <path
          :data-index="0"
          id="cup"
          d="M739.782 683.122H691.174C690.077 683.119 688.993 683.356 687.997 683.818C687.002 684.28 686.119 684.954 685.412 685.794C684.735 685.663 684.046 685.597 683.356 685.596C681.841 685.595 680.341 685.892 678.941 686.472C677.541 687.051 676.269 687.901 675.197 688.973C674.125 690.045 673.275 691.318 672.695 692.72C672.115 694.121 671.816 695.623 671.816 697.14C671.816 698.657 672.115 700.159 672.695 701.56C673.275 702.961 674.125 704.234 675.197 705.307C676.269 706.379 677.541 707.229 678.941 707.808C680.341 708.388 681.841 708.685 683.356 708.684C685.097 708.683 686.815 708.284 688.377 707.516L689.188 717.491L692.41 756.917C692.563 757.409 692.768 757.884 693.023 758.332C693.674 759.529 694.635 760.529 695.806 761.225C696.976 761.922 698.313 762.29 699.674 762.291H731.282C732.645 762.289 733.982 761.921 735.154 761.224C736.326 760.528 737.289 759.529 737.944 758.332C738.191 757.881 738.393 757.407 738.547 756.917L747.047 692.969C747.396 691.831 747.474 690.626 747.274 689.452C747.074 688.279 746.602 687.168 745.895 686.21C745.189 685.253 744.268 684.474 743.206 683.938C742.144 683.401 740.971 683.122 739.782 683.122ZM683.356 705.388C681.173 705.386 679.08 704.516 677.537 702.969C675.995 701.422 675.128 699.326 675.128 697.14C675.128 694.954 675.995 692.857 677.537 691.311C679.08 689.764 681.173 688.894 683.356 688.891C683.505 688.891 683.643 688.931 683.791 688.941C683.466 690.27 683.507 691.662 683.91 692.969L687.389 704.29C686.164 705.003 684.773 705.381 683.356 705.388Z"
          fill="#3F3D56"
        />
      </transition>
      <transition appear @before-enter="beforeEnter" @enter="enter">
        <g id="person" :data-index="0">
          <path
            id="ab6171fa-7d69-4734-b81c-8dff60f9761b"
            d="M477.784 467.095C515.474 467.095 546.028 436.502 546.028 398.765C546.028 361.027 515.474 330.435 477.784 330.435C440.093 330.435 409.539 361.027 409.539 398.765C409.539 436.502 440.093 467.095 477.784 467.095Z"
            fill="#FFB8B8"
          />
          <path
            id="bf427902-b9bf-4946-b5d7-5c1c7e04535e"
            d="M401.655 333.031C402.171 325.184 407.354 317.155 423.82 312.959C423.82 312.959 452.078 276.778 490.479 295.752C490.479 295.752 509.095 292.545 522.899 311.884C522.899 311.884 536.586 303.802 542.36 318.128C542.36 318.128 558.412 347.348 552.502 377.153C546.593 406.958 543.568 410.678 543.568 410.678C543.568 410.678 551.101 349.027 507.715 353.302C464.329 357.577 429.4 342.3 419.574 375.748C411.416 403.521 412.444 417.945 412.444 417.945C412.444 417.945 385.162 390.239 394.438 368.422C399.182 357.264 400.859 345.132 401.655 333.031Z"
            fill="#2F2E41"
          />
          <path
            id="Vector_34"
            d="M292.201 772.186H669.679C667.156 743.356 665.507 633.151 665.507 625.898C665.507 593.907 639.363 558.995 639.363 558.995L630.004 553.816L551.853 529.403L531.629 503.099C529.511 500.336 526.782 498.103 523.657 496.575C520.531 495.047 517.094 494.266 513.616 494.293L449.729 494.773C444.107 494.816 438.703 496.96 434.578 500.784L395.016 537.531L336.991 565.274L336.893 565.176L336.286 565.627L327.675 571.836L322.136 575.844C322.136 575.844 307.605 593.3 295.992 645.654C294.244 653.529 293.018 755.464 292.201 772.186Z"
            fill="#FCA311"
          />
          <path
            id="Vector_35"
            d="M570.782 764.27H383.981L387.934 745.468H566.828L570.782 764.27Z"
            fill="#2F2E41"
          />
          <path
            id="Vector_36"
            d="M629.994 473.89H322.297C319.653 473.894 317.119 474.947 315.25 476.819C313.38 478.691 312.328 481.228 312.324 483.875V691.771C312.327 694.419 313.378 696.957 315.248 698.829C317.118 700.701 319.653 701.754 322.297 701.756H629.994C632.639 701.754 635.174 700.701 637.043 698.829C638.913 696.957 639.964 694.419 639.967 691.771V483.875C639.963 481.228 638.911 478.691 637.041 476.819C635.172 474.947 632.638 473.894 629.994 473.89Z"
            fill="#3F3D56"
          />
          <path
            id="Vector_37"
            d="M510.798 640.47H438.815C437.797 640.472 436.821 640.878 436.102 641.6C435.382 642.322 434.979 643.3 434.98 644.32V758.174H514.642V644.32C514.643 643.814 514.543 643.313 514.35 642.846C514.157 642.379 513.874 641.954 513.517 641.597C513.16 641.239 512.736 640.956 512.269 640.762C511.803 640.569 511.303 640.47 510.798 640.47ZM475.049 705.16C472.797 705.15 470.642 704.25 469.05 702.656C467.458 701.062 466.559 698.904 466.549 696.65V683.518C466.551 681.262 467.448 679.1 469.042 677.506C470.635 675.912 472.796 675.017 475.049 675.017C477.301 675.017 479.462 675.912 481.055 677.506C482.649 679.1 483.546 681.262 483.548 683.518V696.65C483.538 698.904 482.639 701.062 481.047 702.656C479.456 704.25 477.3 705.15 475.049 705.16Z"
            fill="#2F2E41"
          />
          <path
            id="Vector_38"
            d="M434.733 754.532V765.516C434.734 766.086 434.961 766.631 435.363 767.034C435.765 767.436 436.309 767.663 436.878 767.664H512.745C513.313 767.661 513.857 767.434 514.258 767.032C514.66 766.63 514.887 766.085 514.89 765.516V754.532H434.733Z"
            fill="#3F3D56"
          />
          <path
            id="Vector_39"
            d="M623.451 689.598C626.134 689.598 628.309 687.42 628.309 684.734C628.309 682.048 626.134 679.87 623.451 679.87C620.769 679.87 618.594 682.048 618.594 684.734C618.594 687.42 620.769 689.598 623.451 689.598Z"
            fill="#2F2E41"
          />
          <path
            id="Vector_40"
            d="M361.14 677.679C360.916 677.679 360.695 677.723 360.489 677.809C360.283 677.894 360.095 678.02 359.938 678.178C359.78 678.336 359.655 678.523 359.569 678.73C359.484 678.936 359.44 679.158 359.44 679.381V688.139C359.441 688.59 359.62 689.022 359.939 689.34C360.258 689.658 360.69 689.837 361.14 689.837C361.59 689.837 362.022 689.658 362.34 689.34C362.659 689.022 362.839 688.59 362.84 688.139V679.381C362.84 679.158 362.796 678.936 362.71 678.73C362.625 678.523 362.5 678.336 362.342 678.178C362.184 678.02 361.997 677.894 361.79 677.809C361.584 677.723 361.363 677.679 361.14 677.679Z"
            fill="#2F2E41"
          />
          <path
            id="Vector_41"
            d="M370.371 677.679C370.148 677.679 369.927 677.723 369.72 677.809C369.514 677.894 369.327 678.02 369.169 678.178C369.011 678.336 368.886 678.523 368.8 678.73C368.715 678.936 368.671 679.158 368.671 679.381V688.139C368.671 688.591 368.85 689.024 369.169 689.343C369.488 689.662 369.92 689.841 370.371 689.841C370.822 689.841 371.254 689.662 371.573 689.343C371.892 689.024 372.071 688.591 372.071 688.139V679.381C372.071 679.158 372.027 678.936 371.941 678.73C371.856 678.523 371.731 678.336 371.573 678.178C371.415 678.02 371.228 677.894 371.021 677.809C370.815 677.723 370.594 677.679 370.371 677.679Z"
            fill="#2F2E41"
          />
          <path
            id="Vector_42"
            d="M379.602 677.679C379.379 677.679 379.158 677.723 378.952 677.809C378.746 677.894 378.558 678.02 378.4 678.178C378.242 678.336 378.117 678.523 378.032 678.73C377.946 678.936 377.902 679.158 377.902 679.381V688.139C377.903 688.59 378.083 689.022 378.402 689.34C378.72 689.658 379.152 689.837 379.602 689.837C380.052 689.837 380.484 689.658 380.803 689.34C381.122 689.022 381.301 688.59 381.302 688.139V679.381C381.302 679.158 381.258 678.936 381.173 678.73C381.088 678.523 380.962 678.336 380.804 678.178C380.647 678.02 380.459 677.894 380.253 677.809C380.047 677.723 379.826 677.679 379.602 677.679Z"
            fill="#2F2E41"
          />
          <path
            id="Vector_43"
            d="M388.833 677.679C388.61 677.679 388.389 677.723 388.183 677.809C387.977 677.894 387.789 678.02 387.631 678.178C387.474 678.336 387.348 678.523 387.263 678.73C387.178 678.936 387.134 679.158 387.134 679.381V688.139C387.135 688.59 387.314 689.022 387.633 689.34C387.952 689.658 388.383 689.837 388.834 689.837C389.284 689.837 389.715 689.658 390.034 689.34C390.353 689.022 390.532 688.59 390.534 688.139V679.381C390.534 679.158 390.49 678.936 390.404 678.73C390.319 678.523 390.193 678.336 390.036 678.178C389.878 678.02 389.69 677.894 389.484 677.809C389.278 677.723 389.057 677.679 388.833 677.679Z"
            fill="#2F2E41"
          />
          <path
            id="Vector_44"
            d="M396.199 759.322C410.391 759.322 421.896 747.802 421.896 733.592C421.896 719.382 410.391 707.862 396.199 707.862C382.007 707.862 370.501 719.382 370.501 733.592C370.501 747.802 382.007 759.322 396.199 759.322Z"
            fill="#FFB8B8"
          />
          <path
            id="Vector_45"
            d="M575.723 764.269C589.916 764.269 601.421 752.75 601.421 738.54C601.421 724.33 589.916 712.81 575.723 712.81C561.531 712.81 550.026 724.33 550.026 738.54C550.026 752.75 561.531 764.269 575.723 764.269Z"
            fill="#FFB8B8"
          />
        </g>
      </transition>
      <transition appear @before-enter="beforeEnter" @enter="enter">
        <g id="mountains" :data-index="1">
          <path
            id="Vector_46"
            d="M840.968 427.974C838.428 423.769 835.419 419.279 830.707 417.906C825.249 416.316 819.643 419.475 814.888 422.592C800.667 431.914 786.973 442.017 773.866 452.854L773.881 453.017C784.084 452.313 792.553 479.478 802.756 478.774C810.129 478.265 819.489 449.815 826.004 446.324C828.477 444.998 830.886 443.229 833.688 443.105C837.168 442.952 840.203 445.386 842.588 447.929C856.696 462.97 860.778 485.972 876.605 499.187C866.625 474.543 854.709 450.731 840.968 427.974Z"
            fill="#3F3D56"
          />
          <path
            id="Vector_47"
            d="M868.121 464.508C875.563 477.967 883.457 491.158 891.802 504.083V504.093C891.753 504.548 891.684 505.003 891.614 505.458C890.606 503.914 889.608 502.364 888.62 500.807C881.128 489.041 874.008 477.05 867.261 464.835C864.059 459.026 860.936 453.171 857.891 447.269C855.539 442.697 853.236 438.099 850.983 433.474C850.34 432.148 849.718 430.822 849.095 429.486C847.632 426.359 846.189 423.212 844.647 420.125C842.888 416.602 840.743 412.92 837.511 410.555C836.041 409.433 834.283 408.754 832.441 408.596C829.847 408.611 827.306 409.341 825.098 410.704C808.378 419.503 792.772 430.277 778.615 442.796C764.44 455.266 751.769 469.351 740.859 484.766C740.523 485.241 739.732 484.785 740.069 484.3C741.403 482.42 742.747 480.57 744.131 478.729C761.28 455.895 782.307 436.259 806.249 420.718C810.272 418.106 814.374 415.615 818.555 413.247C820.66 412.049 822.775 410.872 824.929 409.763C826.992 408.553 829.305 407.835 831.69 407.665C839.893 407.537 844.143 416.839 847.059 423.093C847.968 425.063 848.894 427.029 849.836 428.991C853.384 436.413 857.071 443.776 860.896 451.079C863.248 455.582 865.657 460.058 868.121 464.508Z"
            fill="#3F3D56"
          />
          <path
            id="Vector_48"
            d="M777.808 438.202C777.332 437.67 776.858 437.138 776.379 436.606C772.596 432.418 768.538 428.279 763.345 425.878C760.868 424.697 758.165 424.07 755.422 424.039C752.592 424.117 749.802 424.726 747.197 425.836C745.958 426.331 744.747 426.893 743.554 427.492C742.191 428.179 740.853 428.911 739.52 429.65C737.019 431.036 734.55 432.482 732.114 433.99C727.267 436.989 722.571 440.218 718.027 443.675C715.672 445.469 713.365 447.32 711.106 449.231C709.006 451.006 706.948 452.828 704.933 454.698C704.502 455.097 703.858 454.452 704.289 454.054C704.82 453.559 705.358 453.066 705.895 452.58C707.412 451.206 708.954 449.859 710.521 448.538C713.377 446.125 716.307 443.805 719.311 441.578C723.982 438.114 728.815 434.884 733.808 431.89C736.303 430.394 738.832 428.962 741.396 427.592C742.17 427.179 742.953 426.775 743.746 426.392C745.543 425.484 747.411 424.723 749.331 424.115C752.05 423.235 754.927 422.952 757.766 423.286C760.507 423.685 763.147 424.6 765.548 425.981C770.648 428.835 774.603 433.248 778.452 437.555C778.84 437.992 778.2 438.64 777.808 438.202Z"
            fill="#3F3D56"
          />
          <path
            id="Vector_49"
            d="M740.492 458.352C739.196 458.416 737.915 458.049 736.85 457.307C734.027 455.166 734.102 450.025 737.075 441.588C737.885 439.302 739.154 437.207 740.804 435.431C742.454 433.656 744.45 432.238 746.669 431.265C748.747 430.334 750.997 429.847 753.273 429.833C755.55 429.819 757.805 430.28 759.895 431.186C760.26 431.344 760.619 431.515 760.974 431.697C763.393 433.053 765.66 434.664 767.737 436.502C769.503 438.036 771.386 439.428 773.37 440.667L774.032 441.056L773.405 441.498C757.394 452.784 746.539 458.352 740.492 458.352Z"
            fill="#3F3D56"
          />
        </g>
      </transition>
      <path
        id="moon"
        d="M802.524 391.353C797.259 391.352 792.158 389.522 788.091 386.175C784.023 382.828 781.242 378.171 780.221 373C775.819 373.892 771.78 376.07 768.613 379.259C765.446 382.448 763.294 386.505 762.427 390.917C761.561 395.329 762.019 399.9 763.744 404.052C765.469 408.204 768.384 411.751 772.121 414.246C775.858 416.741 780.249 418.072 784.74 418.07C789.231 418.069 793.622 416.735 797.357 414.238C801.092 411.741 804.005 408.191 805.727 404.038C807.45 399.885 807.905 395.314 807.036 390.903C805.55 391.202 804.039 391.353 802.524 391.353Z"
        fill="#3F3D56"
      />
      <transition appear @before-enter="beforeEnter" @enter="enter">
        <g id="circles" :data-index="2">
          <path
            id="circle1"
            opacity="0.74"
            d="M193.545 427.804C212.28 427.804 227.468 412.597 227.468 393.838C227.468 375.079 212.28 359.872 193.545 359.872C174.81 359.872 159.622 375.079 159.622 393.838C159.622 412.597 174.81 427.804 193.545 427.804Z"
            fill="#3F3D56"
          />
          <path
            id="circle2"
            opacity="0.74"
            d="M227.468 427.804C246.204 427.804 261.392 412.597 261.392 393.838C261.392 375.079 246.204 359.872 227.468 359.872C208.733 359.872 193.545 375.079 193.545 393.838C193.545 412.597 208.733 427.804 227.468 427.804Z"
            fill="#FCA311"
          />
          <path
            id="circle3"
            opacity="0.74"
            d="M210.794 400.733C229.529 400.733 244.717 385.526 244.717 366.768C244.717 348.009 229.529 332.802 210.794 332.802C192.059 332.802 176.871 348.009 176.871 366.768C176.871 385.526 192.059 400.733 210.794 400.733Z"
            fill="#FF6584"
          />
        </g>
      </transition>
      <path
        id="Vector_53"
        d="M299.499 357.035L212.364 384.444L212.956 386.332L300.091 358.923L299.499 357.035Z"
        fill="#3F3D56"
      />
      <path
        id="Vector_54"
        d="M300.054 361.62C302.237 361.62 304.007 359.848 304.007 357.662C304.007 355.476 302.237 353.704 300.054 353.704C297.87 353.704 296.1 355.476 296.1 357.662C296.1 359.848 297.87 361.62 300.054 361.62Z"
        fill="#3F3D56"
      />
      <path
        id="Vector_55"
        d="M865.729 535.78C871.521 539.867 877.086 544.033 883.451 547.18C883.332 547.665 883.203 548.16 883.085 548.645C882.304 548.278 881.543 547.892 880.782 547.497C874.328 544.112 868.773 539.511 862.715 535.512C859.711 533.434 856.43 531.788 852.969 530.624C849.362 529.538 845.591 529.096 841.831 529.318C826.145 530.089 812.703 539.461 798.125 544.181C790.989 546.497 783.398 547.734 775.956 546.279C768.514 544.825 761.902 540.866 755.329 537.284C748.845 533.741 741.778 530.179 734.208 530.179C728.04 530.188 721.556 532.662 718.255 538.125C718.153 538.288 717.991 538.405 717.804 538.45C717.617 538.496 717.419 538.467 717.253 538.369C717.087 538.271 716.966 538.112 716.915 537.926C716.864 537.74 716.887 537.542 716.98 537.373C717.719 536.128 718.627 534.992 719.679 533.998C723.988 529.911 730.323 528.298 736.145 528.773C743.676 529.387 750.545 532.949 757.059 536.532C763.967 540.332 771.044 544.419 779.03 545.25C786.917 546.081 794.725 544.013 802.039 541.232C816.202 535.839 830.178 526.853 845.932 527.853C853.365 528.328 859.73 531.554 865.729 535.78Z"
        fill="#3F3D56"
      />
      <path
        id="Vector_56"
        d="M245.787 249.576V251.585C245.123 251.453 244.448 251.387 243.771 251.387H233.433V273.613C233.431 276.904 232.125 280.059 229.801 282.386C227.477 284.713 224.325 286.021 221.039 286.023H205.759V289.447C205.759 290.82 206.031 292.179 206.559 293.445H204.444C204.002 292.159 203.778 290.807 203.782 289.447V286.023H195.381V284.044H203.782V261.817C203.784 258.527 205.09 255.371 207.414 253.045C209.738 250.718 212.89 249.41 216.176 249.408H231.456V245.944H233.433V249.408H243.771C244.447 249.408 245.121 249.465 245.787 249.576Z"
        fill="#FCA311"
      />
      <path
        id="Vector_57"
        d="M221.039 233.574H193.444C190.157 233.576 187.006 234.884 184.682 237.211C182.358 239.538 181.052 242.693 181.05 245.984V273.613C181.052 276.904 182.358 280.059 184.682 282.386C187.006 284.713 190.157 286.021 193.444 286.023H221.039C224.326 286.021 227.477 284.713 229.801 282.386C232.125 280.059 233.431 276.904 233.433 273.613V245.944C233.421 242.66 232.11 239.515 229.788 237.196C227.465 234.878 224.319 233.575 221.039 233.574ZM231.456 273.613C231.453 276.379 230.354 279.029 228.401 280.985C226.448 282.94 223.801 284.04 221.039 284.044H193.444C190.682 284.04 188.035 282.94 186.082 280.985C184.129 279.029 183.03 276.379 183.027 273.613V245.984C183.03 243.218 184.129 240.568 186.082 238.612C188.035 236.657 190.682 235.557 193.444 235.553H221.039C223.794 235.556 226.436 236.651 228.388 238.598C230.34 240.545 231.443 243.186 231.456 245.944V273.613Z"
        fill="#3F3D56"
      />
      <path
        id="Vector_58"
        d="M203.782 284.044V286.023H205.759V284.044H203.782ZM203.782 284.044V286.023H205.759V284.044H203.782ZM245.788 249.576C245.121 249.465 244.447 249.408 243.771 249.408H216.176C212.89 249.41 209.738 250.718 207.414 253.045C205.09 255.371 203.784 258.527 203.782 261.817V289.447C203.778 290.807 204.002 292.159 204.444 293.445C205.279 295.898 206.858 298.027 208.962 299.536C211.066 301.044 213.588 301.856 216.176 301.857H243.771C247.058 301.855 250.209 300.547 252.533 298.22C254.857 295.893 256.163 292.738 256.165 289.447V261.817C256.165 258.877 255.121 256.032 253.22 253.79C251.319 251.548 248.685 250.054 245.788 249.576ZM254.189 289.447C254.185 292.212 253.086 294.863 251.133 296.818C249.181 298.774 246.533 299.874 243.771 299.878H216.176C213.414 299.874 210.767 298.773 208.814 296.818C206.861 294.863 205.763 292.212 205.759 289.447V261.817C205.762 259.052 206.861 256.401 208.814 254.446C210.767 252.491 213.414 251.391 216.176 251.387H243.771C246.534 251.388 249.183 252.487 251.136 254.443C253.09 256.399 254.188 259.051 254.189 261.817V289.447ZM203.782 284.044V286.023H205.759V284.044H203.782Z"
        fill="#3F3D56"
      />
      <path
        id="button"
        d="M184.262 542.024H67.0419C66.226 542.024 65.4434 542.348 64.8655 542.925C64.2876 543.502 63.9614 544.285 63.9583 545.102V552.059C67.8256 566.889 72.5077 581.495 77.9832 595.809H184.262C184.666 595.809 185.066 595.73 185.439 595.576C185.812 595.421 186.151 595.194 186.436 594.908C186.722 594.623 186.948 594.283 187.103 593.91C187.257 593.536 187.336 593.136 187.336 592.731V545.102C187.335 544.286 187.011 543.503 186.434 542.926C185.858 542.349 185.077 542.025 184.262 542.024Z"
        fill="#FCA311"
      />
      <path
        id="Vector_59"
        d="M173.093 611.257H84.2395C85.2971 613.741 86.3744 616.205 87.4913 618.659H173.093C177.917 618.728 177.867 611.188 173.093 611.257Z"
        fill="#E4E4E4"
      />
      <path
        id="Vector_60"
        d="M173.093 628.219H91.9487C93.1347 630.703 94.3537 633.17 95.6056 635.621H173.093C177.917 635.69 177.867 628.159 173.093 628.219Z"
        fill="#E4E4E4"
      />
      <path
        id="Vector_61"
        d="M173.093 645.19H100.597C101.921 647.674 103.285 650.138 104.669 652.593H173.093C177.917 652.662 177.867 645.121 173.093 645.19Z"
        fill="#E4E4E4"
      />
      <path
        id="Vector_62"
        d="M780.791 189.017H780.228C781.981 189.018 783.71 189.428 785.278 190.213H782.715C779.953 190.216 777.305 191.316 775.352 193.271C773.399 195.226 772.301 197.877 772.298 200.643V228.273C772.301 231.039 773.399 233.69 775.352 235.645C777.305 237.6 779.953 238.7 782.715 238.703H784.349C783.036 239.219 781.638 239.485 780.228 239.487H780.791C783.815 239.483 786.714 238.279 788.852 236.138C790.99 233.997 792.193 231.095 792.196 228.067V200.436C792.193 197.409 790.99 194.506 788.852 192.365C786.714 190.225 783.815 189.02 780.791 189.017Z"
        fill="#3F3D56"
      />
      <path
        id="Vector_63"
        opacity="0.21"
        d="M780.791 189.017H780.228C781.981 189.018 783.71 189.428 785.278 190.213H782.715C779.953 190.216 777.305 191.316 775.352 193.271C773.399 195.226 772.301 197.877 772.298 200.643V228.273C772.301 231.039 773.399 233.69 775.352 235.645C777.305 237.6 779.953 238.7 782.715 238.703H784.349C783.036 239.219 781.638 239.485 780.228 239.487H780.791C783.815 239.483 786.714 238.279 788.852 236.138C790.99 233.997 792.193 231.095 792.196 228.067V200.436C792.193 197.409 790.99 194.506 788.852 192.365C786.714 190.225 783.815 189.02 780.791 189.017Z"
        fill="black"
      />
      <path
        id="Vector_64"
        d="M707.652 189.017H707.089C708.842 189.018 710.571 189.428 712.139 190.213H709.576C706.814 190.216 704.166 191.316 702.213 193.271C700.26 195.226 699.162 197.877 699.159 200.643V228.273C699.162 231.039 700.26 233.69 702.213 235.645C704.166 237.6 706.814 238.7 709.576 238.703H711.21C709.897 239.219 708.499 239.485 707.089 239.487H707.652C710.676 239.483 713.575 238.279 715.713 236.138C717.851 233.997 719.054 231.095 719.057 228.067V200.436C719.054 197.409 717.851 194.506 715.713 192.365C713.575 190.225 710.676 189.02 707.652 189.017Z"
        fill="#FCA311"
      />
      <path
        id="Vector_65"
        opacity="0.21"
        d="M707.652 189.017H707.089C708.842 189.018 710.571 189.428 712.139 190.213H709.576C706.814 190.216 704.166 191.316 702.213 193.271C700.26 195.226 699.162 197.877 699.159 200.643V228.273C699.162 231.039 700.26 233.69 702.213 235.645C704.166 237.6 706.814 238.7 709.576 238.703H711.21C709.897 239.219 708.499 239.485 707.089 239.487H707.652C710.676 239.483 713.575 238.279 715.713 236.138C717.851 233.997 719.054 231.095 719.057 228.067V200.436C719.054 197.409 717.851 194.506 715.713 192.365C713.575 190.225 710.676 189.02 707.652 189.017Z"
        fill="black"
      />
      <path
        id="Vector_66"
        d="M709.686 238.789C706.924 238.786 704.276 237.686 702.323 235.731C700.371 233.776 699.272 231.125 699.269 228.359V200.729C699.272 197.963 700.371 195.312 702.323 193.357C704.276 191.402 706.924 190.302 709.686 190.299H712.249C710.681 189.514 708.952 189.104 707.199 189.103H679.603C676.579 189.106 673.68 190.311 671.542 192.451C669.404 194.592 668.201 197.495 668.198 200.522V228.153C668.201 231.181 669.404 234.083 671.542 236.224C673.68 238.365 676.579 239.569 679.603 239.573H707.199C708.609 239.571 710.007 239.305 711.32 238.789L709.686 238.789Z"
        fill="#FCA311"
      />
      <path
        id="Vector_67"
        d="M782.825 238.789C780.063 238.786 777.415 237.686 775.462 235.731C773.509 233.776 772.411 231.125 772.408 228.359V200.729C772.411 197.963 773.509 195.312 775.462 193.357C777.415 191.402 780.063 190.302 782.825 190.299H785.388C783.82 189.514 782.091 189.104 780.338 189.103H752.742C749.718 189.106 746.819 190.311 744.681 192.451C742.543 194.592 741.34 197.495 741.337 200.522V228.153C741.34 231.181 742.543 234.083 744.681 236.224C746.819 238.365 749.718 239.569 752.742 239.573H780.338C781.748 239.571 783.146 239.305 784.459 238.789L782.825 238.789Z"
        fill="#3F3D56"
      />
      <path
        id="Vector_68"
        d="M815.412 195.979C825.539 209.929 834.804 224.487 843.155 239.571H825.878C822.855 239.567 819.956 238.362 817.818 236.221C815.68 234.081 814.477 231.178 814.473 228.151V200.521C814.472 198.959 814.791 197.413 815.412 195.979Z"
        fill="#FF6584"
      />
      <path
        id="cursor"
        d="M780.77 254.069L771.103 239.571L764.037 228.972C763.624 228.353 763.045 227.864 762.366 227.561C761.688 227.258 760.937 227.154 760.201 227.26C759.466 227.367 758.776 227.68 758.211 228.164C757.646 228.647 757.229 229.281 757.009 229.992L754.034 239.571L748.045 258.819C747.808 259.586 747.811 260.407 748.052 261.172C748.294 261.937 748.763 262.611 749.396 263.102C750.03 263.593 750.798 263.88 751.599 263.922C752.399 263.965 753.194 263.763 753.876 263.341L756.001 262.025C756.429 261.761 756.917 261.611 757.419 261.589C757.921 261.566 758.42 261.673 758.869 261.898C759.319 262.123 759.703 262.459 759.987 262.874C760.27 263.29 760.443 263.771 760.488 264.272L762.435 285.548C762.527 286.525 762.978 287.434 763.702 288.096C764.425 288.758 765.369 289.127 766.349 289.131C766.468 289.131 766.586 289.121 766.715 289.111L769.7 288.843C770.215 288.797 770.715 288.649 771.173 288.408C771.631 288.167 772.036 287.838 772.367 287.44C772.697 287.042 772.946 286.583 773.099 286.088C773.252 285.594 773.306 285.074 773.258 284.559L771.252 262.589C771.204 262.073 771.293 261.554 771.51 261.083C771.727 260.612 772.063 260.207 772.486 259.908C772.909 259.609 773.403 259.427 773.918 259.38C774.434 259.333 774.953 259.423 775.422 259.64L775.857 259.838C776.636 260.197 777.511 260.292 778.348 260.107C779.185 259.922 779.939 259.469 780.495 258.816C781.052 258.163 781.379 257.345 781.429 256.488C781.478 255.632 781.247 254.782 780.77 254.069Z"
        fill="#CCCCCC"
      />
    </g>
    <defs>
      <clipPath id="clip0_3_4164">
        <rect width="892" height="868" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
<script setup>
import gsap from "gsap";
const beforeEnter = (el) => {
  el.style.opacity = 0;
  el.style.transform = "translateX(-100%)";
};
const enter = (el) => {
  gsap.to(el, {
    opacity: 1,
    x: 0,
    duration: 2,
    ease: "elastic.out(1, 0.9)",
    delay: el.dataset.index * 0.12,
  });
};
</script>
<style scoped>
svg {
  width: 100%;
}
#button {
  animation: breathing 2s infinite;
}
#circle1,
#circle2,
#circle3 {
  transform-box: fill-box;
  transform-origin: center;
}
#circle1 {
  animation: circle1 2s infinite;
}
#circle2 {
  animation: circle2 3s infinite;
}
#circle3 {
  animation: circle3 6s infinite;
}
#cursor {
  transform-box: fill-box;
  transform-origin: center;
  animation: cursor 4s infinite;
}
#clouds {
  transform-box: fill-box;
  transform-origin: center;
}
#cloud1 {
  animation: cloud 3s infinite;
}
#cloud2 {
  animation: cloud2 6s infinite;
}
@keyframes breathing {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
}
@keyframes circle1 {
  0%,
  100% {
    transform: rotate(0deg) translate(0%);
  }
  50% {
    transform: rotate(30deg) translate(-10%);
  }
}
@keyframes circle2 {
  0%,
  100% {
    transform: rotate(0deg) translate(0%);
  }
  50% {
    transform: rotate(90deg) translate(-20%);
  }
}
@keyframes circle3 {
  0%,
  100% {
    transform: rotate(0deg) translate(0%);
  }
  50% {
    transform: rotate(130deg) translate(-10%);
  }
}
@keyframes cursor {
  0%,
  100% {
    transform: translateX(0%);
    scale: 1;
  }
  50% {
    transform: translateX(-80px);
    scale: 1;
  }
  70% {
    transform: translateX(-80px);
    scale: 0.8;
  }
}
@keyframes cloud {
  0%,
  100% {
    transform: translateX(0%);
  }
  50% {
    transform: translate(-2%);
  }
}
@keyframes cloud2 {
  0%,
  100% {
    transform: translateX(0%);
  }
  50% {
    transform: translate(5%);
  }
}
@media screen and (max-width: 1300px) {
  svg {
    height: 300px;
  }
}
</style>
