import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import lottie from "lottie-web";
import Vue3TouchEvents from "vue3-touch-events";
import { defineElement } from "lord-icon-element";
defineElement(lottie.loadAnimation);

//Small Components:
import BounceIcon from "./components/Animations/BounceIcon.vue";
import BounceNext from "./components/Animations/BounceNext.vue";
import TheLoading from "./components/Animations/LoadingSvg.vue";

//Components:
import NicodeLogo from "./components/SVGs/NicodeLogo.vue";
import SectionBadge from "./components/Badges/SectionBadge.vue";
import TheHero from "./views/Hero/TheHero.vue";
import TheServices from "./views/Services/TheServices.vue";

const app = createApp(App);
app.component("nicode-logo", NicodeLogo);
app.component("section-badge", SectionBadge);
app.component("the-services", TheServices);
app.component("the-hero", TheHero);
app.component("bounce-icon", BounceIcon);
app.component("bounce-next", BounceNext);
app.component("the-loading", TheLoading);

app.use(store);
app.use(router);
app.use(Vue3TouchEvents);
app.mount("#app");
