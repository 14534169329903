<template>
  <section-badge>
    <template #textContent>
      <Carousel
        ref="carousel"
        v-if="steps.length !== 0"
        :breakpoints="breakpoints"
        :wrapAround="true"
      >
        <Slide v-for="step in steps" :key="step">
          <the-step @next="next">
            <template v-slot:icon>
              <img
                class="icon__img"
                :src="'/steps/' + step.icon + '.png'"
                alt=""
              />
            </template>
            <template v-slot:h2>
              {{ step.h2 }}
            </template>
            <template v-slot:description>
              {{ step.description }}
            </template>
            <template v-slot:img>
              <component v-if="step.component === 'Analyze'" :is="Analyze" />
              <component v-if="step.component === 'Test'" :is="Test" />
              <component v-if="step.component === 'Design'" :is="Design" />
              <component v-if="step.component === 'Coding'" :is="Coding" />
              <component v-if="step.component === 'Deploy'" :is="Deploy" />
            </template>
          </the-step>
        </Slide>
      </Carousel>
    </template>
  </section-badge>
</template>

<script setup>
import TheStep from "./TheStep.vue";
import Analyze from "./StepsSVGs/AnalyzeSvg.vue";
import Test from "./StepsSVGs/TestSvg.vue";
import Design from "./StepsSVGs/DesignSvg.vue";
import Coding from "./StepsSVGs/CodingSvg.vue";
import Deploy from "./StepsSVGs/DeploySvg.vue";
import { ref } from "vue";
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide } from "vue3-carousel";

const carousel = ref(null);
function next() {
  carousel.value.next();
}

const steps = ref([
  {
    h2: "Analiza wymagań klienta",
    description:
      "na początku należy dokładnie poznać potrzeby i wymagania klienta, aby móc stworzyć stronę zgodną z jego oczekiwaniami. W przypadku firmy detailingowej samochodów, ważne będzie poznanie rodzaju oferowanych usług oraz preferencji w zakresie estetyki i funkcjonalności strony.",
    component: "Analyze",
    icon: "icons/analyze",
  },
  {
    h2: "Projektowanie szablonu strony",
    description:
      "Proces projektowania graficznego aplikacji webowej po analizie branży klienta obejmuje zebranie wymagań, określenie koncepcji i stylu, tworzenie prototypów, dopracowywanie projektu, dostarczenie plików źródłowych oraz wdrażanie projektu. Wszystkie etapy projektowania powinny odbywać się w ścisłej współpracy z klientem.",
    component: "Design",
    icon: "icons/design",
  },
  {
    h2: "Dodanie funkcjonalności",
    description:
      "Oprócz standardowych funkcjonalności, takich jak menu i formularz kontaktowy, można dodać dodatkowe funkcje, które ułatwią korzystanie ze strony dla użytkowników, na przykład galerię zdjęć lub kalkulator wyceny usług.",
    component: "Coding",
    icon: "icons/coding",
  },
  {
    h2: "Testowanie i optymalizacja",
    description:
      "Po zakończeniu implementacji i dodaniu funkcjonalności, należy przetestować stronę i zoptymalizować ją pod kątem szybkości ładowania, pozycjonowania w wyszukiwarkach i wyglądu na różnych urządzeniach. Ważne jest również, aby strona była zgodna z obowiązującymi standardami i przepisami dotyczącymi prywatności użytkowników.",
    component: "Test",
    icon: "icons/test",
  },
  {
    h2: "Wdrożenie strony",
    description:
      "Po zakończeniu testów i optymalizacji, można przystąpić do wdrożenia strony na serwerze. Ważne jest, aby strona była odpowiednio zabezpieczona przed atakami i awariami, a także aby klient otrzymał odpowiednie instrukcje dotyczące zarządzania i aktualizacji strony.",
    component: "Deploy",
    icon: "icons/deploy",
  },
]);
</script>

<style scoped>
.carousel {
  width: 100%;
  height: 100vh;
  z-index: 10;
  overflow: hidden;
}
h2 {
  width: 100%;
  margin: 0;
}
.icon__img {
  width: 100px;
}

.theme__img {
  z-index: 1;
}
@media screen and (max-width: 1560px) {
  h1 {
    font-size: 3rem;
  }
  .icon__img {
    display: none;
  }
}
</style>
