<template>
  <div class="card">
    <router-link to="/services">
      <div class="inner">
        <h1>{{ props.header }}</h1>
        <div @touchstart="openServices" class="btn">
          <h3>Sprawdź!</h3>
          <img src="/Icons/arrowCircle.svg" alt="" />
        </div>
      </div>
    </router-link>
    <img class="bgImage" :src="'/Icons/' + props.Icon + '.svg'" />
  </div>
</template>

<script setup>
import router from "@/router";
import { defineProps } from "vue";
const props = defineProps({
  header: String,
  Icon: String,
});
function openServices() {
  router.push({ path: "/services" });
}
</script>

<style scoped>
.card {
  color: #0e182c;
  background: linear-gradient(
    123.02deg,
    #ffffff 0%,
    rgba(255, 255, 255, 0) 110%
  );
  padding: 7rem 5rem;
  border-radius: 20px;
  width: 500px;
  aspect-ratio: 1/1;
  transition: 0.3s all;
  position: relative;
  backdrop-filter: blur(7.5px);
  cursor: pointer;
  position: relative;
  rotate: 90deg;
  overflow: hidden;
  margin: 10px !important;
}
.inner {
  position: relative;
  z-index: 20;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  flex-direction: column;
}
.card h1 {
  font-size: 2rem;
  line-height: 2.5rem;
  z-index: 1;
}
.bgImage {
  position: absolute;
  bottom: 0;
  right: 2%;
  z-index: 3;
}
.btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  padding: 0.7rem 2rem;
  border-radius: 40px;
  cursor: pointer;
  margin: 2rem 0;
  transition: 0.3s all;
  gap: 1rem;
}
.card::after {
  content: "";
  position: absolute;
  background-color: white;
  left: 6rem;
  bottom: 10rem;
  width: 15%;
  height: 0%;
  transition: 0.3s all;
  border-radius: 50%;
  z-index: 2;
}
.card:hover::after {
  width: 100%;
  height: 100%;
  bottom: 0rem;
  left: 0rem;
  border-radius: 20px;
}
.btn img {
  width: 2rem;
}
@media screen and (max-width: 1500px) {
  .card {
    rotate: 0deg;
  }
}
@media screen and (max-width: 1300px) {
  .card {
    color: #0e182c;
    background: linear-gradient(
      123.02deg,
      #ffffff 0%,
      rgba(255, 255, 255, 0) 110%
    );
    width: fit-content;
    padding: 3rem 2rem;
    width: 400px;
    aspect-ratio: 1/1;
    border-radius: 20px;
    transition: 0.3s all;
    position: relative;
    backdrop-filter: blur(7.5px);
    cursor: pointer;
    position: relative;
  }
  .card h1 {
    font-size: 2rem;
    line-height: 2.5rem;
    z-index: 1;
  }
  .bgImage {
    max-width: 300px;
    position: absolute;
    bottom: 0;
    right: 2%;
    z-index: 3;
  }
  .btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    max-width: unset;
    padding: 0.7rem 2rem;
    border-radius: 40px;
    cursor: pointer;
    margin: 2rem 0;
    transition: 0.3s all;
  }
}
@media screen and (max-width: 700px) {
  .card {
    color: #0e182c;
    background: linear-gradient(
      123.02deg,
      #ffffff 0%,
      rgba(255, 255, 255, 0) 110%
    );
    width: fit-content;
    padding: 3rem 2rem;
    width: 170px;
    aspect-ratio: 1/1;
    border-radius: 10px;
    transition: 0.3s all;
    backdrop-filter: blur(7.5px);
    cursor: pointer;
    position: relative;
  }
  .card h1 {
    font-size: 1rem;
    line-height: 1.3rem;
    z-index: 1;
  }
  .bgImage {
    max-width: 110px;
    position: absolute;
    bottom: unset;
    top: 5%;
    right: 2%;
    z-index: 3;
  }
  .btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    max-width: unset;
    padding: 0.3rem 1rem;
    border-radius: 20px;
    cursor: pointer;
    margin: 0;
    transition: 0.3s all;
    font-size: 0.7rem;
  }
  .btn img {
    width: 1rem;
  }
}
</style>
