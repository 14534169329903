<template>
  <div class="wrapper">
    <main class="section">
      <div class="content">
        <slot name="textContent" />
        <div class="contentBG" ref="bg"></div>
      </div>
    </main>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const bg = ref();

//BG
onMounted(() => {
  gsap.fromTo(
    bg.value,
    { xPercent: -40 },
    { xPercent: 0, duration: 3, ease: "elastic.out(1, 0.6)" }
  );
  gsap.fromTo(
    ".animateText1",
    { xPercent: -60, opacity: 0 },
    { xPercent: 0, opacity: 1, duration: 2.5, ease: "elastic.out(1, 0.6)" }
  );
  gsap.fromTo(
    ".animateText2",
    { yPercent: 40, opacity: 0 },
    { yPercent: 0, opacity: 1, duration: 0.5, delay: 1 }
  );
  gsap.to(bg.value, {
    scrollTrigger: {
      trigger: bg.value,
      start: "center top",
      scrub: 1,
    },
    skewX: -15,
    x: "5%",
    duration: 2,
    ease: "elastic.out(1, 0.9)",
  });
});
</script>

<style>
.wrapper {
  position: relative;
  overflow: hidden;
}
.content {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
  padding: 0 5rem;
  z-index: 1;
  overflow: hidden !important;
}
.contentBG {
  z-index: 0;
  position: fixed;
  top: 0;
  left: -25%;
  width: 85%;
  height: 100%;
  box-shadow: 44px 4px 24px rgba(0, 0, 0, 0.25);
  background: linear-gradient(157.55deg, #0e182c 48.68%, #22365c 85.55%);
  transform: skew(15deg);
  overflow: hidden;
}
@media screen and (max-width: 1300px) {
  .content {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 2rem;
    padding: 0 2rem;
    z-index: 1;
    overflow: hidden !important;
  }
}
@media screen and (max-width: 1000px) {
  .content {
    padding: 0 2rem;
  }
}
@media screen and (max-width: 800px) {
  .contentBG {
    left: -35%;
    width: 110%;
    height: 100%;
    transform: skew(10deg);
  }
}
</style>
