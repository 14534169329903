<template>
  <div class="flex">
    <div class="left">
      <header>
        <div class="icon">
          <slot name="icon" />
        </div>
        <div class="content__box">
          <h2><slot name="h2" /></h2>
          <h3>
            <slot name="description" />
          </h3>
          <bounce-next @click="handleNext"></bounce-next>
        </div>
      </header>
    </div>
    <div class="right">
      <slot name="img" />
    </div>
  </div>
</template>

<script setup>
import { defineEmits } from "vue";

const emit = defineEmits(["next"]);

const handleNext = () => {
  emit("next");
};
</script>

<style scoped>
.flex {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100vh;
}
.left {
  width: 50%;
  text-align: left;
  z-index: 21;
  max-width: 700px;
}
.right {
  width: 50%;
  z-index: 0;
}
header {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: left;
  gap: 1rem;
}
header h2 {
  font-size: 1.3rem;
  font-weight: 500;
}
header h3 {
  font-size: 1rem;
  font-weight: 400;
}

@media screen and (max-width: 1300px) {
  .flex {
    justify-content: center;
    flex-direction: column;
    padding: 0px;
  }
  h2 {
    max-width: 100%;
  }
  .left {
    width: 100%;
  }
  .right {
    width: 100%;
    height: 30%;
  }
  header {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: left;
    gap: 1rem;
  }
  header h2 {
    font-size: 1.2rem;
  }
  header h3 {
    font-size: 0.8em;
  }
}
@media screen and (max-width: 1200px) {
  .left {
    max-width: 500px;
  }
}
</style>
