<template>
  <div class="landingPage">
    <div class="section">
      <section-badge>
        <template #textContent>
          <div class="innerText" ref="hero">
            <h1 class="animateText1">Kreuj swoją przyszłość online</h1>
            <h2 class="animateText2">
              Nasza firma skupia się na dostarczeniu wysokiej jakości produktów,
              poprzez proces projektowania, który obejmuje badanie rynku,
              projektowanie interfejsu użytkownika i testowanie funkcjonalności,
              aby zapewnić satysfakcję klienta
            </h2>
          </div>
          <div class="asset" ref="hero_asset">
            <hero-svg />
          </div>
        </template>
      </section-badge>
    </div>
    <div class="section2">
      <div class="innerText" ref="services">
        <h1>Jedno miejsce, wiele możliwośc</h1>
        <h2>
          Oferujemy szeroki zakres usług związanych z tworzeniem stron
          internetowych oraz aplikacji webowych. Mogą one obejmować
          projektowanie i tworzenie responsywnych stron internetowych,
          rozwijanie aplikacji mobilnych, integrację zewnętrznych systemów,
          implementację e-commerce, zarządzanie treścią oraz hosting i
          utrzymanie serwerów.
        </h2>
        <div class="arrows">
          <img
            @click="next"
            src="/Icons/arrowCircle.svg"
            alt=""
            class="arrowUp"
          />
          <img
            @click="prev"
            src="/Icons/arrowCircle.svg"
            alt=""
            class="arrowDown"
          />
        </div>
      </div>
      <div class="carousel__container" ref="cardsContainer">
        <Carousel
          ref="carousel"
          v-if="cards.length !== 0"
          :breakpoints="breakpoints"
          :wrapAround="true"
        >
          <slide v-for="card in cards" :key="card">
            <the-card :key="card" :header="card.header" :Icon="card.Icon" />
          </slide>
        </Carousel>
      </div>
    </div>
  </div>
</template>

<script setup>
import HeroSvg from "./Hero/HeroSvg.vue";
import TheCard from "./Services/TheCard.vue";
import { Carousel, Slide } from "vue3-carousel";
import { ref, onMounted } from "vue";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
const hero = ref();
const hero_asset = ref();
const cardsContainer = ref();
const services = ref();
const carousel = ref(null);
const cards = [
  {
    header: "Analiza wymagań klienta",
    Icon: "seo",
  },
  {
    header: "Projekt graficzny",
    Icon: "graphicProject",
  },
  {
    header: "Tworzenie strony",
    Icon: "coding",
  },
  {
    header: "Testowanie i optymalizacja",
    Icon: "test",
  },
  {
    header: "Wdrożenie strony",
    Icon: "hosting",
  },
];
const breakpoints = {
  // 700px and up
  300: {
    itemsToShow: 1.35,
  },
  800: {
    itemsToShow: 2,
  },
  1624: {
    itemsToShow: 2.5,
  },
};
function next() {
  carousel.value.prev();
}
function prev() {
  carousel.value.next();
}
//GSAP MAIN
onMounted(() => {
  gsap.to(hero.value, {
    scrollTrigger: {
      trigger: ".landingPage",
      start: "center bottom",
      scrub: 1,
    },
    opacity: 0,
    x: "-10%",
    duration: 2,
    ease: "elastic.out(1, 0.9)",
  });
  gsap.to(hero_asset.value, {
    scrollTrigger: {
      trigger: ".landingPage",
      start: "center bottom",
      scrub: 1,
    },
    opacity: 0,
    x: "10%",
    duration: 2,
    ease: "elastic.out(1, 0.9)",
  });
  gsap.fromTo(
    services.value,
    { opacity: 0, x: -100 },
    {
      scrollTrigger: {
        trigger: ".landingPage",
        start: "50% 30%",
        end: "bottom 2%",
        scrub: 1,
      },
      opacity: 1,
      x: "0%",
      duration: 1,
      ease: "elastic.out(1, 0.9)",
    }
  );
  gsap.fromTo(
    cardsContainer.value,
    { opacity: 0, x: 100 },
    {
      scrollTrigger: {
        trigger: ".landingPage",
        start: "50% 30%",
        end: "bottom 2%",
        scrub: 1,
      },
      opacity: 1,
      x: "0%",
      duration: 1,
      ease: "elastic.out(1, 0.9)",
    }
  );
});
</script>

<style scoped>
.landingPage {
  overflow: hidden;
}
.innerText {
  z-index: 2;
  text-align: left;
}
h1 {
  text-align: left;
  font-size: 4.7rem;
  font-weight: 700;
  max-width: 45%;
  line-height: 6rem;
  margin-bottom: 1rem;
}
h2 {
  font-weight: 300;
  max-width: 45%;
  font-size: 1.2rem;
}
.asset {
  position: absolute;
  right: -15rem;
  bottom: 0;
  max-width: 1300px;
  overflow: hidden;
  z-index: 3;
  transition: 0.2s all;
}
.section2 {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 5rem;
  width: 100%;
}
.section2 .innerText h1 {
  min-width: 150%;
}
.section2 .innerText h2 {
  min-width: 150%;
}
.carousel__container {
  z-index: 4;
  display: flex;
  height: 100vh;
  width: 100%;
}
.carousel {
  height: 100vh;
  transform: rotate(-85deg);
  z-index: 4;
  width: 100%;
  display: flex;
  align-items: center;
}
.carousel__viewport {
  overflow: visible !important;
}
.carousel__slide {
  width: fit-content !important;
}
.arrowUp,
.arrowDown {
  z-index: 400;
  width: 70px;
  cursor: pointer;
  opacity: 0.6;
  transition: 0.3s all;
  left: 0%;
  margin: 1rem 0;
}
.arrowUp:hover,
.arrowDown:hover {
  opacity: 1;
  scale: 1.2;
}
.arrowUp {
  rotate: 270deg;
}
.arrowDown {
  rotate: 90deg;
}
.asset {
  width: 100%;
}
@media screen and (max-width: 1700px) {
  .asset {
    max-width: 1000px;
  }
}
@media screen and (max-width: 1500px) {
  .arrowUp {
    rotate: 180deg;
  }
  .arrowDown {
    rotate: 0deg;
  }
}
@media screen and (max-width: 1300px) {
  .asset {
    position: relative;
    left: 0;
    bottom: 0;
    width: 100%;
    max-width: unset;
    overflow: hidden;
    z-index: 3;
  }
  .innerText {
    padding: 6rem 0;
  }
  h1 {
    text-align: left;
    font-size: 3.5rem;
    font-weight: 700;
    max-width: 45%;
    line-height: 4rem;
    margin-bottom: 1rem;
  }
  h2 {
    font-weight: 300;
    max-width: 65%;
    font-size: 1rem;
  }
}
@media screen and (max-width: 1500px) {
  .section2 {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    padding: 0 5rem;
    width: 100%;
  }
  .section2 .innerText h1 {
    min-width: 70%;
  }
  .section2 .innerText h2 {
    min-width: 70%;
  }
  .carousel__container {
    z-index: 4;
    display: flex;
    height: 100vh;
    width: 100%;
  }
  .carousel__container {
    z-index: 4;
    display: flex;
    height: unset;
    width: 100%;
  }
  .carousel {
    height: unset;
    transform: rotate(0deg);
    z-index: 4;
    width: 100%;
    display: flex;
    align-items: center;
  }
}
.carousel__viewport {
  overflow: visible !important;
}
@media screen and (max-width: 1000px) {
  .innerText {
    padding: 0;
    padding-top: 4rem;
    max-width: 100%;
  }
  h1 {
    text-align: left;
    font-size: 1.5rem;
    font-weight: 700;
    max-width: 95%;
    line-height: 2rem;
    margin-bottom: 1rem;
  }
  h2 {
    font-weight: 300;
    max-width: 95%;
    font-size: 0.9rem;
    line-height: 1.2rem;
  }
}
@media screen and (max-width: 800px) {
  .section2 {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 2rem;
    width: 100%;
  }
  .section2 .innerText h1 {
    min-width: 100%;
    font-size: 1.5rem;
    line-height: 2rem;
  }
  .section2 .innerText h2 {
    min-width: 100%;
    font-size: 0.9rem;
  }
  .arrowUp {
    width: 50px;
    rotate: 180deg;
  }
  .arrowDown {
    width: 50px;
    rotate: 0deg;
  }
}
</style>
<style>
.carousel__viewport {
  overflow: visible !important;
}
</style>
