<template>
  <svg
    width="2257"
    height="484"
    viewBox="0 0 2257 484"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
    class="letter"
      d="M2240.6 396H2217.95C2210.65 396 2202.84 394.763 2194.51 392.289C2186.3 389.815 2178.62 385.779 2171.46 380.18C2164.3 374.451 2158.38 367.094 2153.69 358.109C2149 348.995 2146.66 337.862 2146.66 324.711V95.6094H2197.44V324.711C2197.44 330.961 2199.39 335.974 2203.3 339.75C2207.2 343.396 2212.09 345.219 2217.95 345.219H2240.6V396Z"
      fill="#FCA311"
    />
    <path
    class="letter"
      d="M2104.64 324.711C2104.64 333.435 2103.53 341.312 2101.32 348.344C2099.11 355.245 2096.18 361.365 2092.53 366.703C2088.89 371.911 2084.65 376.404 2079.84 380.18C2075.02 383.826 2069.94 386.82 2064.6 389.164C2059.39 391.508 2054.12 393.266 2048.78 394.438C2043.44 395.479 2038.36 396 2033.55 396H1941.95V345.219H2033.55C2040.32 345.219 2045.4 343.461 2048.78 339.945C2052.17 336.43 2053.86 331.352 2053.86 324.711V256.352C2053.86 249.32 2052.1 244.112 2048.59 240.727C2045.2 237.341 2040.19 235.648 2033.55 235.648H1942.34C1935.43 235.648 1930.23 237.406 1926.71 240.922C1923.2 244.307 1921.44 249.32 1921.44 255.961V462.016H1870.66V255.961C1870.66 247.237 1871.76 239.424 1873.98 232.523C1876.19 225.622 1879.12 219.568 1882.77 214.359C1886.54 209.021 1890.84 204.529 1895.66 200.883C1900.47 197.107 1905.49 194.047 1910.7 191.703C1916.03 189.359 1921.37 187.667 1926.71 186.625C1932.18 185.453 1937.26 184.867 1941.95 184.867H2033.55C2042.27 184.867 2050.08 185.974 2056.98 188.188C2063.89 190.401 2069.94 193.331 2075.15 196.977C2080.49 200.622 2084.98 204.854 2088.62 209.672C2092.4 214.49 2095.46 219.568 2097.8 224.906C2100.15 230.115 2101.84 235.388 2102.88 240.727C2104.05 246.065 2104.64 251.143 2104.64 255.961V324.711Z"
      fill="#FCA311"
    />
    <path
    class="letter"
      d="M1833.21 370.414C1833.21 373.93 1832.49 377.25 1831.06 380.375C1829.76 383.37 1827.94 386.039 1825.59 388.383C1823.38 390.596 1820.71 392.354 1817.59 393.656C1814.59 394.958 1811.4 395.609 1808.02 395.609C1804.5 395.609 1801.18 394.958 1798.05 393.656C1795.06 392.354 1792.39 390.596 1790.05 388.383C1787.83 386.039 1786.08 383.37 1784.77 380.375C1783.47 377.25 1782.82 373.93 1782.82 370.414C1782.82 367.029 1783.47 363.839 1784.77 360.844C1786.08 357.719 1787.83 355.049 1790.05 352.836C1792.39 350.492 1795.06 348.669 1798.05 347.367C1801.18 345.935 1804.5 345.219 1808.02 345.219C1811.4 345.219 1814.59 345.935 1817.59 347.367C1820.71 348.669 1823.38 350.492 1825.59 352.836C1827.94 355.049 1829.76 357.719 1831.06 360.844C1832.49 363.839 1833.21 367.029 1833.21 370.414Z"
      fill="#FCA311"
    />
    <path
    class="letter"
      d="M1763.98 254.789C1763.98 261.82 1762.74 269.438 1760.27 277.641C1757.79 285.714 1753.76 293.266 1748.16 300.297C1742.69 307.198 1735.46 312.992 1726.48 317.68C1717.62 322.367 1706.82 324.711 1694.05 324.711H1602.45V276.469H1694.05C1700.96 276.469 1706.29 274.385 1710.07 270.219C1713.85 265.922 1715.73 260.648 1715.73 254.398C1715.73 247.758 1713.59 242.549 1709.29 238.773C1705.12 234.997 1700.04 233.109 1694.05 233.109H1602.45C1595.55 233.109 1590.21 235.258 1586.44 239.555C1582.66 243.721 1580.77 248.93 1580.77 255.18V326.078C1580.77 332.849 1582.86 338.122 1587.02 341.898C1591.32 345.674 1596.59 347.562 1602.84 347.562H1694.05V396H1602.45C1595.42 396 1587.8 394.763 1579.6 392.289C1571.53 389.815 1563.98 385.844 1556.95 380.375C1550.04 374.776 1544.25 367.549 1539.56 358.695C1534.88 349.711 1532.53 338.839 1532.53 326.078V254.789C1532.53 247.758 1533.77 240.206 1536.24 232.133C1538.72 223.93 1542.69 216.378 1548.16 209.477C1553.76 202.445 1560.98 196.586 1569.84 191.898C1578.82 187.211 1589.69 184.867 1602.45 184.867H1694.05C1701.09 184.867 1708.64 186.104 1716.71 188.578C1724.91 191.052 1732.47 195.089 1739.37 200.688C1746.4 206.156 1752.26 213.383 1756.95 222.367C1761.63 231.221 1763.98 242.029 1763.98 254.789Z"
      fill="#FCA311"
    />
    <path
    class="letter"
      d="M1488.47 324.711C1488.47 333.435 1487.36 341.312 1485.15 348.344C1482.93 355.245 1480.01 361.365 1476.36 366.703C1472.71 371.911 1468.48 376.404 1463.66 380.18C1458.85 383.826 1453.77 386.82 1448.43 389.164C1443.22 391.508 1437.88 393.266 1432.41 394.438C1427.08 395.479 1422 396 1417.18 396H1325.58C1316.85 396 1309.04 394.893 1302.14 392.68C1295.24 390.466 1289.12 387.536 1283.78 383.891C1278.57 380.115 1274.08 375.818 1270.3 371C1266.66 366.182 1263.66 361.169 1261.32 355.961C1258.98 350.622 1257.22 345.284 1256.05 339.945C1255.01 334.477 1254.48 329.398 1254.48 324.711V255.961C1254.48 242.94 1256.83 231.938 1261.52 222.953C1266.33 213.839 1272.26 206.482 1279.29 200.883C1286.45 195.154 1294.2 191.052 1302.53 188.578C1310.86 186.104 1318.55 184.867 1325.58 184.867H1417.18V235.648H1325.97C1319.2 235.648 1314.05 237.406 1310.54 240.922C1307.02 244.307 1305.27 249.32 1305.27 255.961V324.32C1305.27 331.221 1306.96 336.43 1310.34 339.945C1313.86 343.461 1318.94 345.219 1325.58 345.219H1417.18C1424.08 345.219 1429.22 343.461 1432.61 339.945C1435.99 336.43 1437.69 331.352 1437.69 324.711V95.6094H1488.47V324.711Z"
      fill="#FCA311"
    />
    <path
    class="letter"
      d="M1213.52 324.711C1213.52 333.435 1212.41 341.312 1210.2 348.344C1207.98 355.245 1205.05 361.365 1201.41 366.703C1197.76 371.911 1193.53 376.404 1188.71 380.18C1183.89 383.826 1178.82 386.82 1173.48 389.164C1168.27 391.508 1162.93 393.266 1157.46 394.438C1152.12 395.479 1147.04 396 1142.23 396H1050.62C1043.59 396 1035.91 394.763 1027.58 392.289C1019.24 389.815 1011.5 385.779 1004.34 380.18C997.305 374.451 991.38 367.094 986.562 358.109C981.875 348.995 979.531 337.862 979.531 324.711V255.961C979.531 242.94 981.875 231.938 986.562 222.953C991.38 213.839 997.305 206.482 1004.34 200.883C1011.5 195.154 1019.24 191.052 1027.58 188.578C1035.91 186.104 1043.59 184.867 1050.62 184.867H1142.23C1155.25 184.867 1166.32 187.211 1175.43 191.898C1184.54 196.586 1191.9 202.51 1197.5 209.672C1203.1 216.703 1207.14 224.385 1209.61 232.719C1212.21 241.052 1213.52 248.799 1213.52 255.961V324.711ZM1162.73 256.352C1162.73 249.32 1160.98 244.112 1157.46 240.727C1153.95 237.341 1148.87 235.648 1142.23 235.648H1051.02C1044.24 235.648 1039.1 237.406 1035.59 240.922C1032.07 244.307 1030.31 249.32 1030.31 255.961V324.711C1030.31 331.352 1032.07 336.43 1035.59 339.945C1039.1 343.461 1044.24 345.219 1051.02 345.219H1142.23C1149.13 345.219 1154.27 343.461 1157.66 339.945C1161.04 336.43 1162.73 331.352 1162.73 324.711V256.352Z"
      fill="#FCA311"
    />
    <path
    class="letter"
      d="M414.391 141.508H363.609V95.6094H414.391V141.508ZM414.391 396H363.609V184.867H414.391V396Z"
      fill="#FCA311"
    />
    <path
    class="letter"
      d="M309.375 374.32C309.375 377.966 308.659 381.352 307.227 384.477C305.924 387.602 304.102 390.336 301.758 392.68C299.544 394.893 296.875 396.651 293.75 397.953C290.625 399.255 287.305 399.906 283.789 399.906C280.664 399.906 277.474 399.32 274.219 398.148C271.094 396.977 268.294 395.089 265.82 392.484L80.2734 198.734V396H29.4922V137.602C29.4922 132.393 30.9245 127.706 33.7891 123.539C36.7839 119.242 40.5599 116.052 45.1172 113.969C49.9349 112.016 54.8828 111.56 59.9609 112.602C65.0391 113.513 69.401 115.857 73.0469 119.633L258.594 313.188V115.922H309.375V374.32Z"
      fill="#FCA311"
    />
    <path
    class="c"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M910.128 127.683L704.564 9L499 127.683V365.048L704.564 483.73L910.128 365.048V127.683ZM859.774 156.633L704.288 66.8625L548.802 156.633V336.172L704.288 425.942L859.774 336.172V156.633Z"
      fill="#FCA311"
    />
    <path
    class="c"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M821.941 175.161L703.883 107L584.002 176.214V314.641L703.883 383.854L823.765 314.641V312.639L774.372 284.122V284.125L703.882 324.822L633.391 284.125V202.73L703.882 162.032L774.281 202.677L821.941 175.161Z"
      fill="#FCA311"
    />
  </svg>
</template>

<script setup>
import { onMounted } from 'vue';
import gsap from 'gsap';

onMounted(() => {
  const letters = document.querySelectorAll('.letter')
  letters.forEach((letter, index) => {
    gsap.fromTo(letter, { x: -1200, opacity: 0}, { x: 0, opacity: 1, duration: 0.3 + index / 3});
  })
  gsap.fromTo('.c', {y: -700, opacity: 0, rotate: '45deg'}, {y: 0, opacity: 1,duration: 1.5, delay: 2, rotate: '0deg', ease: 'elastic.out(1, 0.3)'})
  
});
</script>

<style scoped>
svg{
  overflow: visible;
}
</style>
