<template>
  <section-badge>
    <template #textContent>
      <div class="innerText" ref="services">
        <h1 class="animateText1">Nasze projekty mówią same za siebie</h1>
      </div>
      <transition name="prompt">
        <div v-if="promptVisible" class="project__Description">
          <a target="blank" :href="promptProperties.link"
            ><div @click="promptVisibleHandler()" class="heading">
              <h3>{{ promptProperties.name }}</h3>
              <img src="/Icons/link.svg" alt="" /></div
          ></a>
          <img
            @click="promptVisibleHandler"
            class="close"
            src="/Icons/x.png"
            alt=""
          />
          <h5>
            {{ promptProperties.description }}
          </h5>
        </div>
      </transition>
      <img
        @click="next"
        src="/Icons/arrowCircle.svg"
        alt=""
        class="arrowLeft"
      />
      <img
        @click="prev"
        src="/Icons/arrowCircle.svg"
        alt=""
        class="arrowRight"
      />
      <div class="carousel">
        <Carousel
          ref="carousel"
          v-if="slides.length !== 0"
          :breakpoints="breakpoints"
          :wrapAround="true"
        >
          <Slide
            v-for="slide in slides"
            :key="slide"
            v-touch:swipe.left="doSwipe"
            v-touch:swipe.right="doSwipe"
            @touchend="promptVisibleHandler(slide)"
            @click="promptVisibleHandler(slide)"
          >
            <div class="phone" ref="phones">
              <img :src="'/SVG/' + slide.img + '.svg'" alt="" />
            </div>
          </Slide>
        </Carousel>
      </div>
    </template>
  </section-badge>
</template>

<script setup>
import { gsap } from "gsap";
import { ref, onMounted } from "vue";
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide } from "vue3-carousel";

function doSwipe() {
  promptVisible.value = false;
}
let promptVisible = ref(false);
let promptProperties = ref({
  name: "",
  link: "",
  description: "",
});
function promptVisibleHandler(el) {
  if (el) {
    promptProperties.value = {
      name: el.name,
      link: el.link,
      description: el.description,
    };
  }
  promptVisible.value = !promptVisible.value;
}
const phones = ref();
const carousel = ref(null);
function next() {
  carousel.value.prev();
}
function prev() {
  carousel.value.next();
}
onMounted(() => {
  phones.value.forEach((phone, index) => {
    gsap.fromTo(
      phone,
      { yPercent: 300, opacity: 0, rotate: 20, scale: 0.8 },
      {
        yPercent: 0,
        opacity: 1,
        rotate: 0,
        scale: 1,
        duration: 2,
        delay: 1 / index,
        ease: "elastic.out(1, 0.8)",
      }
    );
  });
});
const slides = [
  {
    img: "mockup1",
    name: "DziuplaDetailing.com",
    link: "https://dziupladetailing.com/",
    description:
      "W procesie tworzenia strony dla salonu detailingowego, skupiliśmy się na najważniejszych elementach. W ramach tego procesu, skoncentrowaliśmy się na stworzeniu strony, która będzie skupiała się przede wszystkim na prezentacji oferty usług, wartości i unikalnych cech firmy. W treściach strony wyeksponowaliśmy usługi detailingu samochodowego, korzystając z odpowiednich zdjęć, aby wizualnie przedstawić ofertę. Dodatkowo, dodaliśmy sekcję opinii klientów, aby potwierdzić jakość usług i zachęcić potencjalnych klientów do skorzystania z oferty firmy. Ostatecznie, efektem naszej pracy była strona internetowa, która odpowiada na potrzeby klienta i pomaga w osiągnięciu biznesowych celów firmy.",
  },
  {
    img: "mockup2",
    name: "KM-Instal.pl",
    link: "https://KM-Instal.pl/",
    description:
      "Przy projektowaniu strony wizytówki dla firmy zajmującej się instalacjami pomp ciepła skupiliśmy się na następujących elementach: \n Wygląd i układ strony: Strona ma minimalistyczny design z jasnym tłem, by zachęcić użytkownika do skupienia się na treści. Główne sekcje strony są łatwe do zauważenia i pozwalają szybko znaleźć informacje na temat firmy i jej usług. Kolorystyka: W celu podkreślenia ekologicznego charakteru firmy i jej działalności, wykorzystałem kolory związane z naturą, takie jak zieleń i niebieski. Informacje o firmie i usługach: Strona zawiera informacje o firmie i jej usługach, takie jak opis oferowanych usług i technologii stosowanych przy instalacji pomp ciepła. Formularz kontaktowy: Na stronie znajduje się formularz kontaktowy, który umożliwia łatwy kontakt z firmą i zgłaszanie zapytań dotyczących usług. Galeria zdjęć: Strona zawiera galerię zdjęć, która prezentuje instalacje pomp ciepła wykonane przez firmę. Celem projektu strony wizytówki było zaprezentowanie firmy zajmującej się instalacjami pomp ciepła jako ekologicznej i nowoczesnej, z dbałością o jakość usług i zadowolenie klientów.",
  },
  {
    img: "mockup3",
    name: "Informatyk1.pl",
    link: "https://Informatyk1.pl/",
    description:
      "Przy projektowaniu strony wizytówki dla firmy outsourcingowej IT skupiliśmy się na następujących elementach: \n Wygląd i układ strony: Strona ma nowoczesny design, z jasnym tłem i kolorami kojarzącymi się z technologią i innowacją. Główne sekcje strony są łatwe do zauważenia i pozwalają szybko znaleźć informacje na temat firmy i jej usług. Informacje o firmie i usługach: Strona zawiera informacje o firmie i jej usługach, takie jak opis oferowanych usług IT, technologii stosowanych przez firmę oraz doświadczeniu firmy w realizacji projektów IT. Celem projektu strony wizytówki było zaprezentowanie firmy outsourcingowej IT jako nowoczesnej i innowacyjnej, z dbałością o jakość usług i zadowolenie klientów, a także jako atrakcyjnego pracodawcę dla specjalistów IT.",
  },
  {
    img: "mockup4",
    name: "WiosennaPark.pl",
    link: "https://WiosennaPark.pl/",
    description:
      "Przy projektowaniu strony internetowej dla Dewelopera skupiłem się na następujących elementach: \n Wygląd i układ strony: Strona ma nowoczesny i przyciągający wzrok design, z jasnym tłem i kolorami kojarzącymi się z przyrodą, np. zielony i biały. Główne sekcje strony są łatwe do zauważenia i pozwalają szybko znaleźć informacje na temat osiedla. Kolorystyka: Kolorystyka strony ma na celu podkreślenie przyjaznego charakteru osiedla i związanych z nim wartości, takich jak spokój, harmonia i bliskość natury. Informacje o osiedlu: Strona zawiera szczegółowe informacje o osiedlu, takie jak plany i wizualizacje domów, apartamentów czy ogólny opis lokalizacji. Usługi i udogodnienia: Na stronie umieszczone są informacje o usługach i udogodnieniach dostępnych dla mieszkańców osiedla, np. ośrodek sportowy, plac zabaw, itp. Kontakt: Na stronie znajduje się formularz kontaktowy, który umożliwia łatwy kontakt z przedstawicielami dewelopera, a także dane kontaktowe do biura sprzedaży.",
  },
];
const breakpoints = {
  // 700px and up
  300: {
    itemsToShow: 2,
  },
  700: {
    itemsToShow: 3,
  },
  // 1024 and up
  1124: {
    itemsToShow: 3.5,
  },
  1324: {
    itemsToShow: 4,
  },
};
</script>

<style scoped>
.innerText {
  position: absolute;
  top: 10%;
}
.innerText h1 {
  max-width: 60%;
}
.carousel {
  z-index: 4;
  width: 100%;
  overflow: visible !important;
  position: absolute;
  bottom: 10%;
  left: 0;
}
.project__Description {
  position: absolute;
  z-index: 6;
  width: clamp(500px, 40%, 30%);
  display: flex;
  align-items: left;
  justify-content: space-between;
  gap: 1rem;
  flex-direction: column;
  text-align: left;
  padding: 3rem;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(7.5px);
  right: 5%;
  top: 18%;
  transition: 0.5s all;
}
.heading {
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 1rem;
  cursor: pointer;
}
.heading:hover.heading img {
  transform: scale(1.2);
}
.heading:hover.heading h3 {
  text-decoration: underline;
}
.heading img {
  width: 35px;
  transition: 0.15s all;
}
.close {
  position: absolute;
  right: 1rem;
  top: 1rem;
  cursor: pointer;
  width: 50px;
  z-index: 100;
  transition: 0.2s all;
}
.close:hover {
  transform: scale(1.1);
}
.project__Description h3 {
  font-size: 2rem;
  color: #0e182c;
  transition: 0.3s all;
}
.project__Description h5 {
  font-size: 1rem;
  font-weight: 400;
  color: #0e182c;
}
.phone {
  cursor: pointer;
}
.phone img {
  width: 300px;
  position: relative;
  overflow: visible;
  z-index: 1;
}
.phone::after {
  content: url("shadowTop.svg");
  position: absolute;
  z-index: 0;
  width: 50%;
  height: 45%;
  top: 0;
  right: -40%;
  pointer-events: none;
}
.phone::before {
  content: url("shadowBottom.svg");
  position: absolute;
  z-index: 0;
  width: 50%;
  height: 45%;
  bottom: -40%;
  left: -0;
  pointer-events: none;
}
.arrowLeft,
.arrowRight {
  z-index: 400;
  width: 70px;
  cursor: pointer;
  opacity: 0.6;
  height: 70px;
  transition: 0.3s all;
  bottom: 35vh;
}
.arrowLeft:hover,
.arrowRight:hover {
  opacity: 1;
  scale: 1.2;
}
.arrowLeft {
  rotate: 180deg;
  position: absolute;
  left: 3%;
}
.arrowRight {
  rotate: 0deg;
  position: absolute;
  right: 3%;
}
.carousel__viewport {
  overflow: visible !important;
}
.carousel__slide {
  transform: scale(1) !important;
  transition: 0.4s all;
}
.carousel__slide--visible {
  z-index: 2 !important;
}
.carousel__slide--next,
.carousel__slide--prev {
  z-index: 3;
}
.carousel__slide--active {
  z-index: 4 !important;
}
.carousel__slide--visible {
  transform: scale(1) !important;
}
.carousel__slide--next {
  transform: scale(1.1) !important;
}
.carousel__slide--prev {
  transform: scale(1.1) !important;
}
.carousel__slide--active {
  transform: scale(1.2) !important;
}
@media screen and (max-width: 1000px) {
  .phone img {
    width: 250px;
  }
  .innerText {
    width: 90%;
  }
  .carousel {
    bottom: 10%;
  }
  .innerText h1 {
    font-size: 3rem;
    line-height: 3.5rem;
  }
  .arrowLeft,
  .arrowRight {
    width: 50px;
    bottom: 30vh;
  }
}
@media screen and (max-width: 500px) {
  .innerText {
    top: 15%;
  }
  .innerText h1 {
    max-width: 100%;
    font-size: 1.5rem !important;
    line-height: 2rem;
  }
  .phone img {
    width: 180px;
  }
  .carousel {
    bottom: 10%;
  }
  .arrowLeft,
  .arrowRight {
    width: 40px;
    bottom: 30vh;
  }
  .project__Description {
    position: absolute;
    z-index: 6;
    width: 90%;
    display: flex;
    align-items: left;
    justify-content: space-between;
    gap: 0.5rem;
    flex-direction: column;
    text-align: left;
    padding: 2rem 1rem;
    border-radius: 6px;
    background-color: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(7.5px);
    right: unset;
    left: 5%;
    top: unset;
    transition: 0.5s all;
    max-height: 70%;
    overflow-y: scroll;
    z-index: 2000;
  }
  .project__Description h3 {
    font-size: 1.2rem;
    color: #0e182c;
    transition: 0.3s all;
  }
  .project__Description h5 {
    font-size: 0.8rem;
    font-weight: 400;
    color: #0e182c;
  }
  .project__Description .heading img {
    width: 30px;
  }
}
.prompt-enter-active,
.prompt-leave-active {
  transition: opacity 0.5s ease;
}

.prompt-enter-from,
.prompt-leave-to {
  opacity: 0;
}
@media screen and (max-width: 400px) {
  .phone img {
    width: 150px;
  }
}
</style>
