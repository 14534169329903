<template>
  <div>
    <svg
      @mouseleave="bounceIconLeave($event.target)"
      @mouseenter="bounceIconEnter($event.target)"
      width="254"
      height="64"
      viewBox="0 0 254 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        ref="arrow"
        d="M130 32.0001L119.333 21.3334L119.333 42.6667L130 32.0001ZM148.667 32.0001C148.667 35.689 147.966 39.1556 146.565 42.4001C145.166 45.6445 143.267 48.4667 140.867 50.8667C138.467 53.2667 135.644 55.1663 132.4 56.5654C129.155 57.9663 125.689 58.6667 122 58.6667C118.311 58.6667 114.844 57.9663 111.6 56.5654C108.355 55.1663 105.533 53.2667 103.133 50.8667C100.733 48.4667 98.8328 45.6445 97.4319 42.4001C96.0328 39.1556 95.3333 35.689 95.3333 32.0001C95.3333 28.3112 96.0328 24.8445 97.4319 21.6001C98.8328 18.3556 100.733 15.5334 103.133 13.1334C105.533 10.7334 108.355 8.83386 111.6 7.43475C114.844 6.03386 118.311 5.33342 122 5.33342C125.689 5.33342 129.155 6.03386 132.4 7.43475C135.644 8.83386 138.467 10.7334 140.867 13.1334C143.267 15.5334 145.166 18.3556 146.565 21.6001C147.966 24.8445 148.667 28.3112 148.667 32.0001Z"
        fill="#FCA311"
      />

      <path
        d="M6 37.3333C8.94552 37.3333 11.3333 34.9455 11.3333 32C11.3333 29.0545 8.94552 26.6667 6 26.6667C3.05448 26.6667 0.666666 29.0545 0.666667 32C0.666667 34.9455 3.05448 37.3333 6 37.3333ZM79 31L6 31L6 33L79 33L79 31Z"
        fill="#FCA311"
      />
      <path
        ref="line"
        @click="handleNext"
        d="M248 26.6667C245.054 26.6667 242.667 29.0545 242.667 32C242.667 34.9455 245.054 37.3333 248 37.3333C250.946 37.3333 253.333 34.9455 253.333 32C253.333 29.0545 250.946 26.6667 248 26.6667ZM162 33L248 33L248 31L162 31L162 33Z"
        fill="#FCA311"
      />
    </svg>
  </div>
</template>

<script setup>
import gsap from "gsap";
import { ref } from "vue";
const arrow = ref();
const line = ref();
function bounceIconEnter() {
  gsap.to(line.value, {
    scaleX: 1.2,
    ease: "elastic.out(1, 0.2)",
    duration: 1.3,
  });
  gsap.to(arrow.value, {
    scale: 1.05,
    y: -1,
    x: -1,
    ease: "elastic.out(1, 0.2)",
    duration: 1.3,
  });
}
function bounceIconLeave() {
  gsap.to(line.value, {
    scaleX: 1,
    ease: "elastic.out(1, 0.2)",
    duration: 1.3,
  });
  gsap.to(arrow.value, {
    scale: 1,
    y: 0,
    x: 0,
    ease: "elastic.out(1, 0.2)",
    duration: 1.3,
  });
}
</script>

<style scoped>
svg {
  margin: 15% 0;
  width: 100%;
  justify-self: center;
  cursor: pointer;
}
@media screen and (max-width: 800px) {
  svg {
    margin: 5%;
    height: 40px;
  }
}
</style>
