<template>
  <svg
    width="941"
    height="660"
    viewBox="0 0 941 660"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="tests" clip-path="url(#clip0_3_4574)">
      <transition appear @before-enter="beforeEnter" @enter="enter">
        <g id="table" :data-index="0">
          <path
            id="Rectangle 62"
            d="M842.26 21.3652H63.3578V391.161H842.26V21.3652Z"
            fill="#E6E6E6"
          />
          <path
            id="Rectangle 75"
            d="M819.988 53.0759H85.6308V361.608H819.988V53.0759Z"
            fill="white"
          />
          <path
            id="Rectangle 80"
            d="M841.928 0H63.0257V33.1074H841.928V0Z"
            fill="#FCA311"
          />
          <path
            id="Ellipse 90"
            d="M87.6158 21.4502C91.003 21.4502 93.7489 18.7028 93.7489 15.3138C93.7489 11.9248 91.003 9.17749 87.6158 9.17749C84.2285 9.17749 81.4826 11.9248 81.4826 15.3138C81.4826 18.7028 84.2285 21.4502 87.6158 21.4502Z"
            fill="white"
          />
          <path
            id="Ellipse 91"
            d="M110.895 21.4502C114.282 21.4502 117.028 18.7028 117.028 15.3138C117.028 11.9248 114.282 9.17749 110.895 9.17749C107.508 9.17749 104.762 11.9248 104.762 15.3138C104.762 18.7028 107.508 21.4502 110.895 21.4502Z"
            fill="white"
          />
          <path
            id="Ellipse 92"
            d="M134.175 21.4502C137.563 21.4502 140.309 18.7028 140.309 15.3138C140.309 11.9248 137.563 9.17749 134.175 9.17749C130.788 9.17749 128.042 11.9248 128.042 15.3138C128.042 18.7028 130.788 21.4502 134.175 21.4502Z"
            fill="white"
          />
          <path
            id="Path 142"
            d="M128.578 251.577C127.726 251.58 126.911 251.921 126.31 252.524C125.709 253.128 125.371 253.945 125.371 254.797C125.371 255.649 125.709 256.466 126.31 257.07C126.911 257.673 127.726 258.014 128.578 258.017H305.173C306.024 258.014 306.84 257.673 307.441 257.07C308.042 256.466 308.379 255.649 308.379 254.797C308.379 253.945 308.042 253.128 307.441 252.524C306.84 251.921 306.024 251.58 305.173 251.577H128.578Z"
            fill="#E6E6E6"
          />
          <path
            id="Path 143"
            d="M128.578 270.899C127.726 270.902 126.911 271.242 126.31 271.846C125.709 272.449 125.371 273.267 125.371 274.119C125.371 274.971 125.709 275.788 126.31 276.391C126.911 276.995 127.726 277.336 128.578 277.339H218.733C219.585 277.336 220.4 276.995 221.001 276.391C221.602 275.788 221.94 274.971 221.94 274.119C221.94 273.267 221.602 272.449 221.001 271.846C220.4 271.242 219.585 270.902 218.733 270.899H128.578Z"
            fill="#E6E6E6"
          />
          <path
            id="Path 142_2"
            d="M423.633 107.035C422.521 107.037 421.454 107.48 420.668 108.268C419.882 109.056 419.441 110.124 419.441 111.237C419.441 112.35 419.882 113.418 420.668 114.205C421.454 114.993 422.521 115.437 423.633 115.438H774.853C775.966 115.437 777.032 114.993 777.818 114.205C778.604 113.418 779.046 112.35 779.046 111.237C779.046 110.124 778.604 109.056 777.818 108.268C777.032 107.48 775.966 107.037 774.853 107.035H423.633Z"
            fill="#E6E6E6"
          />
          <path
            id="Path 143_2"
            d="M423.633 132.247C422.521 132.249 421.454 132.692 420.668 133.48C419.882 134.268 419.441 135.335 419.441 136.449C419.441 137.562 419.882 138.629 420.668 139.417C421.454 140.205 422.521 140.648 423.633 140.65H662.064C663.176 140.648 664.243 140.205 665.029 139.417C665.815 138.629 666.256 137.562 666.256 136.449C666.256 135.335 665.815 134.268 665.029 133.48C664.243 132.692 663.176 132.249 662.064 132.247H423.633Z"
            fill="#E6E6E6"
          />
          <path
            id="Path 142_3"
            d="M423.633 156.888C422.521 156.89 421.454 157.333 420.668 158.121C419.882 158.909 419.441 159.977 419.441 161.09C419.441 162.203 419.882 163.271 420.668 164.058C421.454 164.846 422.521 165.29 423.633 165.291H774.853C775.966 165.29 777.032 164.846 777.818 164.058C778.604 163.271 779.046 162.203 779.046 161.09C779.046 159.977 778.604 158.909 777.818 158.121C777.032 157.333 775.966 156.89 774.853 156.888H423.633Z"
            fill="#E6E6E6"
          />
          <path
            id="Path 143_3"
            d="M423.633 182.1C422.521 182.102 421.454 182.545 420.668 183.333C419.882 184.121 419.441 185.188 419.441 186.301C419.441 187.415 419.882 188.482 420.668 189.27C421.454 190.058 422.521 190.501 423.633 190.503H662.064C663.176 190.501 664.243 190.058 665.029 189.27C665.815 188.482 666.256 187.415 666.256 186.301C666.256 185.188 665.815 184.121 665.029 183.333C664.243 182.545 663.176 182.102 662.064 182.1H423.633Z"
            fill="#E6E6E6"
          />
          <g id="buttons">
            <path
              id="button1"
              d="M305.395 307.377H238.313C237.444 307.376 236.611 307.03 235.996 306.416C235.382 305.801 235.036 304.967 235.035 304.098V293.345C235.036 292.475 235.382 291.642 235.996 291.027C236.611 290.412 237.444 290.067 238.313 290.066H305.395C306.263 290.067 307.096 290.412 307.711 291.027C308.325 291.642 308.671 292.475 308.672 293.345V304.098C308.671 304.967 308.325 305.801 307.711 306.416C307.096 307.03 306.263 307.376 305.395 307.377Z"
              fill="#FCA311"
            />
            <path
              id="button2"
              d="M305.395 195.396H238.313C237.444 195.395 236.611 195.049 235.996 194.435C235.382 193.82 235.036 192.987 235.035 192.117V181.364C235.036 180.495 235.382 179.661 235.996 179.046C236.611 178.432 237.444 178.086 238.313 178.085H305.395C306.263 178.086 307.096 178.432 307.711 179.046C308.325 179.661 308.671 180.495 308.672 181.364V192.117C308.671 192.987 308.325 193.82 307.711 194.435C307.096 195.049 306.263 195.395 305.395 195.396Z"
              fill="#FCA311"
            />
            <path
              id="button3"
              d="M775.883 236.628H708.8C707.932 236.627 707.099 236.282 706.484 235.667C705.87 235.052 705.524 234.219 705.523 233.35V222.596C705.524 221.727 705.87 220.893 706.484 220.279C707.099 219.664 707.932 219.318 708.8 219.317H775.883C776.751 219.318 777.584 219.664 778.199 220.279C778.813 220.893 779.159 221.727 779.16 222.596V233.35C779.159 234.219 778.813 235.052 778.199 235.667C777.584 236.282 776.751 236.627 775.883 236.628Z"
              fill="#FCA311"
            />
          </g>

          <path
            id="Path 155"
            d="M204.636 111.586C203.444 111.59 202.48 113.139 202.482 115.045C202.485 116.946 203.447 118.487 204.636 118.49H306.381C307.573 118.487 308.537 116.938 308.535 115.032C308.533 113.13 307.57 111.59 306.381 111.586H204.636Z"
            fill="#E4E4E4"
          />
          <path
            id="Path 156"
            d="M204.636 131.59C203.444 131.594 202.48 133.142 202.482 135.049C202.485 136.95 203.447 138.49 204.636 138.494H306.381C307.573 138.49 308.537 136.942 308.535 135.035C308.533 133.134 307.57 131.594 306.381 131.59H204.636Z"
            fill="#E4E4E4"
          />
          <path
            id="Path 157"
            d="M204.636 151.352C203.444 151.355 202.48 152.904 202.482 154.81C202.485 156.712 203.447 158.252 204.636 158.256H248.417C249.609 158.252 250.573 156.703 250.571 154.797C250.568 152.896 249.606 151.355 248.417 151.352H204.636Z"
            fill="#E4E4E4"
          />
          <path
            id="Vector"
            d="M152.976 162.283C168.224 162.283 180.585 149.915 180.585 134.659C180.585 119.403 168.224 107.035 152.976 107.035C137.727 107.035 125.366 119.403 125.366 134.659C125.366 149.915 137.727 162.283 152.976 162.283Z"
            fill="#E4E4E4"
          />
        </g>
      </transition>
      <transition appear @before-enter="beforeEnter" @enter="enter">
        <g id="person2" :data-index="1">
          <path
            id="Vector_2"
            d="M884.415 442.388C883.439 441.218 882.73 439.848 882.34 438.374C881.95 436.901 881.888 435.36 882.157 433.86C882.427 432.359 883.022 430.937 883.901 429.691C884.779 428.446 885.92 427.409 887.243 426.652L880.422 390.234L898.164 397.692L901.921 431.132C903.044 433.439 903.281 436.079 902.589 438.55C901.897 441.021 900.323 443.152 898.165 444.54C896.007 445.927 893.416 446.474 890.882 446.078C888.347 445.681 886.047 444.368 884.415 442.388Z"
            fill="#FFB6B6"
          />
          <path
            id="Vector_3"
            d="M724.668 231.158C724.472 232.669 724.61 234.205 725.072 235.658C725.534 237.11 726.309 238.444 727.342 239.564C728.375 240.685 729.641 241.565 731.05 242.143C732.46 242.721 733.979 242.983 735.501 242.91L749.3 277.294L760.261 261.468L745.477 231.243C745.185 228.693 743.968 226.34 742.058 224.627C740.147 222.915 737.675 221.964 735.11 221.953C732.545 221.942 730.065 222.874 728.141 224.57C726.216 226.267 724.981 228.611 724.668 231.158Z"
            fill="#FFB6B6"
          />
          <path
            id="Vector_4"
            d="M880.245 644.683H867.54L861.495 595.652L880.248 595.653L880.245 644.683Z"
            fill="#FFB6B6"
          />
          <path
            id="Vector_5"
            d="M883.485 657.006L842.519 657.004V656.486C842.519 652.255 844.199 648.197 847.189 645.205C850.18 642.213 854.235 640.532 858.464 640.532H858.465L883.486 640.533L883.485 657.006Z"
            fill="#2F2E41"
          />
          <path
            id="Vector_6"
            d="M838.67 644.28L826.557 640.442L835.584 591.873L853.461 597.536L838.67 644.28Z"
            fill="#FFB6B6"
          />
          <path
            id="Vector_7"
            d="M838.042 657.006L798.986 644.634L799.143 644.14C800.419 640.106 803.245 636.745 806.998 634.796C810.751 632.846 815.125 632.468 819.156 633.745L843.011 641.302L838.042 657.006Z"
            fill="#2F2E41"
          />
          <path
            id="Vector_8"
            d="M823.622 405.048C823.622 405.048 804.771 434.664 811.432 461.095C818.094 487.527 825.348 538.333 825.348 538.333L861.466 624.334L888.389 623.235L861.652 521.837L862.709 454.041C862.709 454.041 881.293 421.64 877.674 411.554C874.055 401.468 823.622 405.048 823.622 405.048Z"
            fill="#2F2E41"
          />
          <path
            id="Vector_9"
            d="M885.208 415.637C885.208 415.637 887.527 445.016 880.273 457.458C873.018 469.901 865.29 517.258 865.29 517.258L852.96 611.971L827.42 603.656L849.198 497.541L866.315 414.069L885.208 415.637Z"
            fill="#2F2E41"
          />
          <path
            id="Vector_10"
            d="M894.263 273.416L874.337 259.712L844.014 258.831L833.454 269.189L817.339 277.422L815.847 411.554C815.847 411.554 864.103 434.696 890.064 423.526L902.553 319.038C907.735 298.3 894.263 273.416 894.263 273.416Z"
            fill="#3F3D56"
          />
          <path
            id="Vector_11"
            d="M821.387 313.266H768.236L733.316 253.127L756.022 244.128L775.252 278.352L803.084 275.832L820.463 274.991C823.247 274.857 826.028 275.332 828.61 276.385C831.192 277.438 833.513 279.042 835.41 281.086C837.308 283.13 838.736 285.565 839.595 288.218C840.454 290.872 840.723 293.682 840.383 296.451C839.809 301.089 837.562 305.357 834.064 308.454C830.566 311.55 826.058 313.262 821.387 313.266Z"
            fill="#3F3D56"
          />
          <path
            id="Vector_12"
            d="M861.648 247.785C876.168 247.785 887.939 236.008 887.939 221.48C887.939 206.952 876.168 195.175 861.648 195.175C847.127 195.175 835.356 206.952 835.356 221.48C835.356 236.008 847.127 247.785 861.648 247.785Z"
            fill="#FFB6B6"
          />
          <path
            id="Vector_13"
            d="M893.327 215.495C892.519 209.72 888.61 204.237 883.05 202.487C880.842 196.07 874.992 191.346 868.529 189.287C862.544 187.378 856.094 187.51 850.193 189.664C844.291 191.817 839.271 195.87 835.919 201.187C835.425 201.965 833.904 203.876 833.946 204.805C834 205.984 835.803 207.202 836.709 207.945C838.954 209.681 841.375 211.176 843.933 212.406C853.387 217.31 850.24 225.017 850.111 233.75C850.049 237.994 851.465 241.618 855.082 244.042C860.302 247.541 867.773 246.149 873.453 244.745C879.898 243.151 885.256 237.487 888.931 232.025C892.186 227.188 894.134 221.27 893.327 215.495Z"
            fill="#2F2E41"
          />
          <path
            id="Vector_14"
            d="M893.509 307.662L903.74 419.937L870.871 418.173L893.509 307.662Z"
            fill="#3F3D56"
          />
          <path
            id="Vector_15"
            d="M1006.77 660H604.37C604.044 659.999 603.731 659.868 603.5 659.637C603.27 659.406 603.14 659.092 603.14 658.765C603.14 658.439 603.27 658.125 603.5 657.894C603.731 657.662 604.044 657.532 604.37 657.531H1006.77C1007.09 657.531 1007.41 657.661 1007.64 657.892C1007.87 658.124 1008 658.438 1008 658.765C1008 659.093 1007.87 659.407 1007.64 659.638C1007.41 659.87 1007.09 660 1006.77 660Z"
            fill="#CACACA"
          />
        </g>
      </transition>
      <transition appear @before-enter="beforeEnter" @enter="enter">
        <g id="person1" :data-index="2">
          <path
            id="Vector_16"
            d="M87.1429 647.691H96.9972L101.685 609.66H87.1408L87.1429 647.691Z"
            fill="#9E616A"
          />
          <path
            id="Vector_17"
            d="M84.6291 644.472L104.037 644.471C107.317 644.472 110.463 645.775 112.783 648.096C115.102 650.417 116.405 653.564 116.405 656.846V657.248L84.6296 657.249L84.6291 644.472Z"
            fill="#2F2E41"
          />
          <path
            id="Vector_18"
            d="M177.975 647.691H187.829L192.518 609.66H177.973L177.975 647.691Z"
            fill="#9E616A"
          />
          <path
            id="Vector_19"
            d="M175.461 644.472L194.869 644.471C198.149 644.472 201.295 645.775 203.615 648.096C205.934 650.417 207.237 653.564 207.237 656.846V657.248L175.462 657.249L175.461 644.472Z"
            fill="#2F2E41"
          />
          <path
            id="Vector_20"
            d="M166.076 342.16L172.506 383.176L111.417 383.98L119.454 344.572L166.076 342.16Z"
            fill="#9E616A"
          />
          <path
            id="Vector_21"
            d="M170.734 373.161L190.191 424.997L192.602 639.812H176.124L136.737 467.22L100.565 635.307H84.4882L95.7417 446.309C95.7417 446.309 92.4232 408.375 109.407 385.991L113.541 374.041L170.734 373.161Z"
            fill="#2F2E41"
          />
          <path
            id="Vector_22"
            d="M87.2741 413.57C88.975 413.106 90.5537 412.275 91.8988 411.134C93.2439 409.994 94.3228 408.573 95.0594 406.97C95.7961 405.367 96.1726 403.623 96.1624 401.859C96.1523 400.095 95.7557 398.355 95.0006 396.761L120.989 311.987L101.874 308.195L78.1043 391.292C75.512 392.738 73.5402 395.086 72.5625 397.89C71.5847 400.694 71.669 403.76 72.7993 406.506C73.9296 409.252 76.0274 411.488 78.6952 412.79C81.363 414.091 84.4154 414.369 87.2741 413.57Z"
            fill="#9E616A"
          />
          <path
            id="Vector_23"
            d="M121.55 364.813C121.194 364.813 120.838 364.777 120.488 364.708C119.312 364.472 118.245 363.858 117.45 362.959C116.656 362.06 116.177 360.925 116.088 359.728C115.483 351.451 112.489 337.042 107.188 316.901C105.399 310.056 105.795 302.822 108.322 296.213C110.849 289.605 115.379 283.953 121.277 280.05C127.176 276.147 134.147 274.189 141.214 274.45C148.281 274.711 155.089 277.178 160.684 281.506C164.267 284.251 167.257 287.694 169.474 291.626C171.692 295.559 173.09 299.899 173.587 304.387C176.221 327.6 171.255 350.906 168.997 359.849C168.7 361.016 168.029 362.054 167.087 362.803C166.144 363.553 164.982 363.973 163.779 363.998L121.667 364.812C121.628 364.812 121.589 364.813 121.55 364.813Z"
            fill="#3F3D56"
          />
          <g class="hand">
            <path
              id="Vector_24"
              d="M184.031 311.002L164.815 327.794C164.269 328.272 163.625 328.626 162.93 328.833C162.234 329.04 161.501 329.094 160.783 328.992C160.064 328.89 159.376 328.634 158.765 328.242C158.154 327.849 157.634 327.33 157.242 326.719L143.498 305.305C141.068 302.493 139.852 298.831 140.117 295.123C140.382 291.415 142.107 287.964 144.913 285.526C147.718 283.089 151.376 281.865 155.082 282.122C158.789 282.38 162.242 284.098 164.684 286.9L183.985 303.349C184.537 303.82 184.982 304.405 185.288 305.064C185.594 305.722 185.755 306.439 185.759 307.165C185.764 307.891 185.612 308.61 185.313 309.272C185.015 309.934 184.578 310.524 184.031 311.002Z"
              fill="#3F3D56"
            />
            <path
              id="Vector_25"
              d="M239.201 290.347C238.948 290.915 238.741 291.501 238.58 292.102L185.869 310.023L175.683 301.12L160.245 314.744L176.746 332.257C178.079 333.672 179.835 334.616 181.75 334.948C183.664 335.279 185.635 334.98 187.366 334.096L243.77 305.27C245.81 306.521 248.182 307.122 250.572 306.992C252.961 306.862 255.254 306.007 257.146 304.541C259.038 303.076 260.439 301.068 261.163 298.786C261.887 296.504 261.899 294.056 261.198 291.767C260.498 289.477 259.117 287.456 257.24 285.971C255.362 284.486 253.078 283.608 250.69 283.454C248.302 283.3 245.924 283.877 243.872 285.108C241.819 286.34 240.19 288.167 239.201 290.347Z"
              fill="#9E616A"
            />
          </g>
          <path
            id="Vector_26"
            d="M117.953 333.693L94.2071 324.359C93.5314 324.093 92.921 323.685 92.4172 323.162C91.9134 322.639 91.5282 322.014 91.2878 321.328C91.0474 320.643 90.9574 319.914 91.024 319.191C91.0906 318.467 91.3123 317.767 91.6738 317.137L104.343 295.07C105.721 291.619 108.41 288.855 111.821 287.384C115.232 285.914 119.087 285.857 122.54 287.226C125.994 288.595 128.763 291.278 130.242 294.687C131.721 298.096 131.788 301.953 130.428 305.411L124.722 330.127C124.558 330.835 124.244 331.499 123.801 332.074C123.358 332.649 122.796 333.122 122.154 333.461C121.511 333.799 120.804 333.995 120.079 334.035C119.354 334.075 118.629 333.958 117.953 333.693Z"
            fill="#3F3D56"
          />
          <path
            id="Vector_27"
            d="M135.556 269.225C149.822 269.225 161.386 257.654 161.386 243.381C161.386 229.108 149.822 217.538 135.556 217.538C121.291 217.538 109.726 229.108 109.726 243.381C109.726 257.654 121.291 269.225 135.556 269.225Z"
            fill="#9E616A"
          />
          <path
            id="Vector_28"
            d="M403.63 660H1.23393C0.907292 659.999 0.594354 659.868 0.36372 659.637C0.133086 659.406 0.00358009 659.092 0.00358009 658.765C0.00358009 658.439 0.133086 658.125 0.36372 657.894C0.594354 657.662 0.907292 657.532 1.23393 657.531H403.63C403.956 657.532 404.269 657.662 404.5 657.894C404.73 658.125 404.86 658.439 404.86 658.765C404.86 659.092 404.73 659.406 404.5 659.637C404.269 659.868 403.956 659.999 403.63 660Z"
            fill="#CACACA"
          />
          <path
            id="Vector_29"
            d="M153.325 232.992C158.867 232.961 164.409 232.93 169.951 232.899C170.345 229.12 168.664 225.36 166.215 222.457C163.634 219.663 160.69 217.228 157.462 215.217C154.284 213.034 150.99 210.802 147.211 210.039C144.044 209.4 140.77 209.844 137.57 210.289C133.326 210.879 129.082 211.47 124.839 212.06C120.18 212.708 115.306 213.441 111.523 216.237C106.406 220.017 104.449 226.64 102.805 232.787L93.704 266.813L147.458 272.454C149.403 272.789 151.395 272.731 153.317 272.284C154.274 272.043 155.139 271.526 155.805 270.798C156.471 270.069 156.908 269.161 157.063 268.186C157.217 265.992 155.478 264.197 154.066 262.512C150.697 258.335 148.675 253.234 148.267 247.882C147.859 242.53 149.084 237.181 151.781 232.541L153.325 232.992Z"
            fill="#2F2E41"
          />
        </g>
      </transition>
    </g>
    <defs>
      <clipPath id="clip0_3_4574">
        <rect width="1008" height="660" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
<script setup>
import gsap from "gsap";
const beforeEnter = (el) => {
  el.style.opacity = 0;
  el.style.transform = "translateX(-100%)";
};
const enter = (el) => {
  gsap.to(el, {
    opacity: 1,
    x: 0,
    duration: 2,
    ease: "elastic.out(1, 0.9)",
    delay: el.dataset.index * 0.12,
  });
};
</script>
<style scoped>
svg {
  width: 100%;
}
#button1,
#button2,
#button3 {
  animation: breathing 2s infinite;
}
#button3 {
  animation-delay: 0.5s;
}
#button2 {
  animation-delay: 0.3s;
}
.hand {
  animation: hand 3s infinite;
}
@keyframes breathing {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
}
@keyframes hand {
  0%,
  100% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(1deg);
  }
}
@media screen and (max-width: 1300px) {
  svg {
    height: 300px;
  }
}
</style>
