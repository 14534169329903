<template>
  <svg
    width="986"
    height="657"
    viewBox="0 0 986 657"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="undraw_business_chat_re_gg4h 1" clip-path="url(#clip0_3_4812)">
      <transition appear @before-enter="beforeEnter" @enter="enter">
        <g id="Person2" :data-index="0">
          <path
            id="Vector"
            d="M741.278 25.355C733.832 29.5392 721.815 12.9891 719.091 21.0845C716.368 29.1798 722.964 61.844 726.367 69.6779C728.058 73.5718 730.449 77.1332 732.109 81.0408C733.769 84.9483 734.659 89.4412 733.192 93.4251C730.639 100.358 722.213 103.179 718.035 109.272C713.17 116.369 715.135 125.929 717.41 134.228C719.37 141.381 721.459 148.598 725.233 154.983C729.007 161.368 734.697 166.928 741.807 169.04C750.859 171.73 760.534 168.534 769.26 164.925C777.65 161.455 785.95 157.522 793.086 151.909C800.222 146.296 806.176 138.845 808.67 130.115C811.799 119.165 809.185 107.182 803.768 97.1643C798.35 87.147 790.336 78.8016 782.113 70.9226C768.264 57.6526 753.526 45.3107 738.03 34.007L741.278 25.355Z"
            fill="#2F2E41"
          />
          <path
            id="Vector_2"
            d="M852.012 587.22L844.982 652.83L844.662 655.83L765.132 655.52L764.712 652.51L756.292 592.39L852.012 587.22Z"
            fill="#FFB6B6"
          />
          <path
            id="Vector_3"
            d="M730.413 652.38C730.413 653.37 730.403 654.37 730.393 655.38L647.833 655.06L647.563 652.05L642.473 594.98L727.843 584.63C727.843 584.63 730.593 614.9 730.413 652.38Z"
            fill="#FFB6B6"
          />
          <path
            id="Vector_4"
            d="M721.223 141.579C734.337 158.099 757.459 162.887 776.119 153.053C802.755 139.017 839.121 122.93 845.548 135.785C855.896 156.481 855.896 176.566 855.896 176.566L866.244 417.769C866.244 417.769 871.418 515.159 806.743 506.563C742.067 497.967 641.174 534.185 641.174 534.185L651.48 427.33L678.999 387.042L690.146 271.458L670.924 178.47L694.208 168.122C694.208 168.122 696.795 150.013 704.556 150.013C712.317 150.013 714.191 132.617 721.015 141.315C721.083 141.402 721.152 141.49 721.223 141.579Z"
            fill="#FCA311"
          />
          <path
            id="Vector_5"
            d="M861.532 655.9L844.662 655.83L765.132 655.52L754.782 655.48L754.182 652.47L738.182 571.7L735.682 652.4L735.592 655.4L730.392 655.38L647.832 655.06L641.912 655.03L641.892 652.03L641.542 594.99L641.222 542.17L641.172 534.18L641.182 534.17L665.902 484.06L673.112 469.44L674.592 466.44L689.032 437.17L819.692 419.08L821.692 418.8L857.192 413.89L857.452 428.44L857.602 436.7L858.132 466.44L858.192 469.44L858.252 472.87L861.472 652.89L861.532 655.9Z"
            fill="#2F2E41"
          />
          <path
            id="Vector_6"
            d="M759.877 133.907C789.716 133.907 813.905 109.718 813.905 79.8791C813.905 50.0405 789.716 25.8516 759.877 25.8516C730.039 25.8516 705.85 50.0405 705.85 79.8791C705.85 109.718 730.039 133.907 759.877 133.907Z"
            fill="#FFB6B6"
          />
          <path
            id="Vector_7"
            d="M820.782 164.494C808.172 182.482 801.723 204.707 802.746 226.651C788.449 219.726 778.799 205.093 775.515 189.549C772.231 174.006 774.736 157.685 780.014 142.701C780.067 142.551 780.12 142.401 780.173 142.252C788.186 119.76 780.741 94.6231 761.386 80.6418C748.331 71.2111 737.008 59.3797 728.318 45.8177C723.412 38.1613 719.242 28.5569 722.907 20.2348C726.285 12.5664 735.009 8.98227 743.038 6.5841C761.37 1.10804 780.849 -2.00791 799.666 1.45718C818.483 4.92227 836.597 15.7434 845.491 32.6843C854.384 49.6252 852.054 72.7452 837.619 85.304C842.172 86.776 845.393 91.6015 845.006 96.3706C844.62 101.14 840.663 105.383 835.933 106.102C839.019 105.993 840.841 110.058 839.74 112.943C838.64 115.829 835.802 117.621 833.094 119.106C829.237 121.222 824.962 123.434 824.946 128.974C824.939 131.195 825.731 133.351 827.025 135.156C830.161 139.531 835.225 141.501 840.099 143.351C829.79 146.388 821.569 155.602 819.723 166.19L820.782 164.494Z"
            fill="#2F2E41"
          />
          <path
            id="uuid-cb65f836-e7d9-440e-b153-5c66ba7afb8d-1092"
            d="M804.946 468.419C793.075 477.234 778.905 478.254 773.296 470.698C767.687 463.143 772.764 449.874 784.641 441.057C789.337 437.471 794.823 435.06 800.641 434.028L851.411 397.313L868.132 421.505L816.307 455.131C813.636 460.404 809.74 464.96 804.946 468.419Z"
            fill="#FFB6B6"
          />
          <path
            id="Vector_8"
            d="M836.932 169.416C836.932 169.416 877.886 170.709 885.647 199.166C893.408 227.624 908.93 288.279 906.343 372.496C906.343 372.496 919.278 426.824 885.647 424.237L839.081 444.933L808.037 403.541L833.907 372.496L836.932 169.416Z"
            fill="#FCA311"
          />
          <g id="hand">
            <path
              id="uuid-949a288c-1385-4c96-9df8-4f861626eeb5-1090"
              d="M590.399 143.593C588.925 128.124 595.711 114.825 605.555 113.888C615.4 112.952 624.572 124.731 626.045 140.205C626.718 146.379 625.903 152.623 623.668 158.417L629.088 224.036L598.24 225.923L596.173 161.034C592.883 155.765 590.903 149.784 590.399 143.593Z"
              fill="#FFB6B6"
            />
            <path
              id="Vector_9"
              d="M758.462 174.722C758.462 174.722 708.065 145.077 697.689 149.524C687.313 153.97 636.917 229.565 636.917 229.565L632.284 215.77L596.896 219.19C596.896 219.19 576.144 315.536 622.094 306.643C668.044 297.749 743.639 214.743 743.639 214.743L758.462 174.722Z"
              fill="#FCA311"
            />
          </g>
        </g>
      </transition>
      <transition appear @before-enter="beforeEnter" @enter="enter">
        <g id="table" :data-index="1">
          <path
            id="Vector_10"
            d="M982.762 615.41L919.622 482.74C914.912 472.84 904.782 466.44 893.812 466.44H96.2925C93.2622 466.44 90.3125 466.91 87.5322 467.81C80.5024 470.06 74.5225 474.98 70.9824 481.74L3.30225 610.76C-1.37768 619.67 -1.06762 630.15 4.13233 638.77C9.32227 647.4 18.4324 652.58 28.5024 652.62L641.912 655.03L647.832 655.06L730.392 655.38L735.592 655.4L754.782 655.48L765.132 655.52L844.662 655.83L861.532 655.9L956.842 656.27H956.962C966.802 656.27 975.812 651.31 981.092 643C986.392 634.65 987.012 624.33 982.762 615.41Z"
            fill="white"
          />
          <path
            id="Vector_11"
            d="M982.762 615.41L919.622 482.74C914.912 472.84 904.782 466.44 893.812 466.44H96.2925C93.2622 466.44 90.3125 466.91 87.5322 467.81C80.5024 470.06 74.5225 474.98 70.9824 481.74L3.30225 610.76C-1.37768 619.67 -1.06762 630.15 4.13233 638.77C9.32227 647.4 18.4324 652.58 28.5024 652.62L641.912 655.03L647.832 655.06L730.392 655.38L735.592 655.4L754.782 655.48L765.132 655.52L844.662 655.83L861.532 655.9L956.842 656.27H956.962C966.802 656.27 975.812 651.31 981.092 643C986.392 634.65 987.012 624.33 982.762 615.41ZM978.562 641.39C973.832 648.84 965.772 653.27 956.962 653.27H956.852L861.472 652.89L844.982 652.83L764.712 652.51L754.182 652.47L735.682 652.4L730.412 652.38L647.562 652.05L641.892 652.03L28.5122 649.62C19.5024 649.58 11.3423 644.95 6.70239 637.23C2.05224 629.51 1.77246 620.13 5.9624 612.15L73.6423 483.14C76.0823 478.49 79.8022 474.81 84.2524 472.44C87.8823 470.5 92.0024 469.44 96.2925 469.44H893.812C903.632 469.44 912.692 475.17 916.912 484.03L980.052 616.69C983.862 624.69 983.302 633.92 978.562 641.39Z"
            fill="#3F3D56"
          />
        </g>
      </transition>
      <transition appear @before-enter="beforeEnter" @enter="enter">
        <g id="papers" :data-index="2">
          <path
            id="Vector_12"
            d="M283.418 562.44L434.418 507.44L358.918 477.074L208.418 516.44L283.418 562.44Z"
            fill="#F2F2F2"
          />
          <path
            id="Vector_13"
            d="M513.418 479.44L496.418 533.44L695.418 545.44L678.418 484.44L513.418 479.44Z"
            fill="#F2F2F2"
          />
        </g>
      </transition>
      <transition appear @before-enter="beforeEnter" @enter="enter">
        <g id="Person1" :data-index="3">
          <path
            id="Vector_14"
            d="M290.895 334.007C290.895 334.007 319.35 317.325 327.013 332.793C334.676 348.262 405.471 532.797 405.471 532.797L359.851 541.68L290.895 334.007Z"
            fill="#FCA311"
          />
          <path
            id="Vector_15"
            d="M228.832 223.77L230.173 225.68C225.172 228.567 224.435 235.85 226.78 241.135C229.125 246.42 233.664 250.337 237.59 254.582C241.516 258.828 245.095 264.086 244.731 269.86C244.322 276.353 239.029 281.7 238.291 288.166C237.226 297.581 245.992 305.841 245.557 315.31C245.228 322.54 239.668 328.349 234.152 333.039C229.836 336.716 225.137 340.411 222.224 345.154C221.424 346.46 220.758 347.854 220.27 349.346C218.093 356.079 220.145 363.416 222.908 369.926C225.661 376.437 229.169 382.797 230.031 389.814C230.884 396.83 228.237 404.931 221.779 407.809C234.641 406.707 247.191 402.222 257.841 394.93C258.8 394.272 259.733 393.597 260.657 392.905C275.756 381.535 286.362 365.112 296.567 349.106C297.411 347.792 298.246 346.469 299.09 345.154C301.355 341.592 303.629 338.039 305.903 334.478C312.387 324.299 318.951 313.969 322.486 302.44C325.737 291.808 326.287 280.572 327.62 269.531C328.268 264.122 329.121 258.73 329.281 253.286C329.538 244.91 328.108 236.498 325.195 228.638C322.344 220.91 318.062 213.707 312.546 207.578C306.897 201.298 299.987 196.191 292.384 192.514C285.021 188.952 277.009 186.74 268.846 186.127C266.11 185.923 263.384 185.888 260.657 186.012C249.9 186.456 239.268 189.174 228.93 192.514C221.326 196.191 214.416 201.298 208.767 207.578C203.251 213.707 198.97 220.91 196.119 228.638C206.031 223.344 217.809 221.523 228.832 223.77Z"
            fill="#2F2E41"
          />
          <path
            id="Vector_16"
            d="M305.918 320.94L254.918 300.94L253.627 320.94L208.918 327.94L187.918 422.94L206.918 481.94L348.918 485.94L343.918 465.94C343.918 465.94 366.918 439.94 352.918 397.94L351.363 387.251C347.298 359.303 330.516 334.816 305.918 320.94Z"
            fill="#FCA311"
          />
          <path
            id="Vector_17"
            d="M277.533 300.041C302.551 300.041 322.832 279.76 322.832 254.742C322.832 229.724 302.551 209.443 277.533 209.443C252.515 209.443 232.234 229.724 232.234 254.742C232.234 279.76 252.515 300.041 277.533 300.041Z"
            fill="#A0616A"
          />
          <path
            id="Vector_18"
            d="M192.033 253.286C192.193 258.73 193.046 264.122 193.694 269.531C195.027 280.572 195.577 291.808 198.828 302.439C202.363 313.969 208.927 324.299 215.411 334.478C217.685 338.039 219.959 341.592 222.224 345.154C223.068 346.469 223.903 347.792 224.746 349.107C234.952 365.112 245.557 381.535 260.657 392.905C261.581 393.597 262.513 394.272 263.473 394.93C274.122 402.222 286.673 406.707 299.534 407.809C293.077 404.931 290.43 396.83 291.283 389.814C292.144 382.797 295.653 376.437 298.406 369.926C301.169 363.416 303.22 356.079 301.044 349.346C300.556 347.854 299.89 346.46 299.09 345.154C296.177 340.411 291.478 336.716 287.161 333.039C281.646 328.349 276.085 322.54 275.757 315.31C275.321 305.841 269.273 309.012 268.207 299.597C267.47 293.131 258.845 282.108 258.436 275.615C258.072 269.842 261.616 255.879 265.542 251.633C269.468 247.388 265.862 234.713 268.207 229.428C270.552 224.143 296.141 228.567 291.141 225.68L292.482 223.77C303.505 221.523 315.282 223.344 325.195 228.638C327.353 229.783 329.414 231.089 331.368 232.563C336.084 223.948 330.489 213.014 322.877 206.805C315.424 200.712 306.24 197.195 297.127 194.095C295.555 193.562 293.974 193.029 292.384 192.514C282.045 189.174 271.413 186.456 260.657 186.012C257.93 185.888 255.203 185.923 252.468 186.127C244.305 186.74 236.293 188.952 228.93 192.514C221.327 196.191 214.416 201.298 208.767 207.578C203.251 213.707 198.97 220.91 196.119 228.638C193.206 236.498 191.776 244.91 192.033 253.286Z"
            fill="#2F2E41"
          />
          <path
            id="uuid-bc099db1-a2ce-4512-8b55-449b58adbd20-1091"
            d="M278.845 488.677C292.837 490.064 303.462 498.408 302.578 507.313C301.694 516.217 289.635 522.308 275.639 520.918C270.04 520.439 264.638 518.627 259.883 515.633L200.675 509.005L204.377 481.287L262.353 490.763C267.605 488.76 273.26 488.045 278.845 488.677Z"
            fill="#A0616A"
          />
          <path
            id="Vector_19"
            d="M219.418 333.44L208.918 327.94C208.918 327.94 192.418 328.44 181.418 339.44C170.418 350.44 81.4182 476.44 81.4182 476.44C81.4182 476.44 59.4182 510.44 114.418 515.44C169.418 520.44 230.418 520.44 230.418 520.44L235.418 479.44L152.418 470.44L210.54 393.44L219.418 333.44Z"
            fill="#FCA311"
          />
        </g>
      </transition>
      <transition appear @before-enter="beforeEnter" @enter="enter">
        <g id="personOnline" :data-index="4">
          <g id="Group">
            <path
              id="Vector_20"
              d="M531.448 320.12C521.358 323.11 510.698 324.8 499.688 324.96C499.038 324.98 498.388 324.99 497.739 324.99C487.778 324.99 478.108 323.76 468.858 321.44L469.098 319.07L474.479 267.18L474.818 263.86L469.828 248.89H531.048L530.069 263.52L529.708 268.85L530.238 270.54L543.738 313.56L544.339 315.48C540.158 317.27 535.858 318.82 531.448 320.12Z"
              fill="#FCA311"
            />
            <path
              id="Vector_21"
              d="M468.859 321.44C454.329 317.81 440.849 311.49 428.979 303.06C424.089 300.41 419.339 297.54 414.789 294.5L417.039 293.37L473.569 265.1L474.479 267.18L498.819 322.98L499.689 324.96C499.039 324.98 498.389 324.99 497.739 324.99C487.779 324.99 478.109 323.76 468.859 321.44Z"
              fill="#2F2E41"
            />
            <path
              id="Vector_22"
              d="M592.018 278.52C591.768 278.86 591.508 279.19 591.248 279.52C587.028 284.88 582.348 289.86 577.289 294.41L577.249 291.74L577.108 281.91C577.108 281.91 581.148 278.96 586.138 278.19C587.328 278 588.578 277.94 589.839 278.08C590.568 278.15 591.288 278.29 592.018 278.52Z"
              fill="#2F2E41"
            />
            <path
              id="Vector_23"
              d="M577.289 294.41C567.609 303.14 556.489 310.3 544.339 315.48C540.159 317.27 535.859 318.82 531.449 320.12L531.399 318.05L530.239 270.54L530.069 263.52L586.139 278.19L589.119 278.97L591.249 279.52C587.029 284.88 582.349 289.86 577.289 294.41Z"
              fill="#2F2E41"
            />
            <path
              id="Vector_24"
              d="M501.016 232.667C525.819 232.667 545.926 212.561 545.926 187.757C545.926 162.954 525.819 142.847 501.016 142.847C476.212 142.847 456.105 162.954 456.105 187.757C456.105 212.561 476.212 232.667 501.016 232.667Z"
              fill="#A0616A"
            />
          </g>
          <path
            id="Circle"
            d="M531.402 318.05C521.063 321.17 510.132 322.88 498.822 322.98L499.693 324.96C510.703 324.8 521.362 323.11 531.453 320.12L531.402 318.05ZM417.043 293.37C393.993 272.07 379.362 241.79 378.773 208.15C355.243 235.39 378.622 270.35 414.793 294.5C419.342 297.54 424.092 300.41 428.983 303.06C424.793 300.09 420.802 296.85 417.043 293.37ZM590.862 131.98C569.043 104.58 535.412 86.99 497.743 86.99C432.132 86.99 378.743 140.37 378.743 205.99C378.743 206.71 378.753 207.43 378.773 208.15C379.392 207.42 380.052 206.7 380.743 205.99C413.743 171.99 433.233 88.99 497.743 88.99C535.453 88.99 569.052 106.92 590.463 134.71C605.683 154.45 614.743 179.18 614.743 205.99C614.743 209.88 614.552 213.73 614.173 217.52C613.933 220.04 613.602 222.54 613.193 225.01C610.213 243.16 603.043 259.93 592.713 274.26C591.782 275.56 590.822 276.83 589.842 278.08C589.602 278.38 589.372 278.68 589.122 278.97C585.483 283.52 581.512 287.79 577.253 291.74C567.483 300.82 556.162 308.24 543.743 313.56C539.743 315.29 535.622 316.79 531.402 318.05C521.063 321.17 510.132 322.88 498.822 322.98C498.463 322.99 498.102 322.99 497.743 322.99C488.683 322.99 478.973 321.6 469.102 319.07C455.622 315.62 441.832 310.06 428.983 303.06C440.852 311.49 454.332 317.81 468.862 321.44C478.112 323.76 487.782 324.99 497.743 324.99C498.392 324.99 499.043 324.98 499.693 324.96C510.703 324.8 521.362 323.11 531.453 320.12C535.862 318.82 540.162 317.27 544.342 315.48C556.493 310.3 567.612 303.14 577.293 294.41C582.352 289.86 587.032 284.88 591.253 279.52C591.512 279.19 591.773 278.86 592.023 278.52C592.362 278.08 592.703 277.63 593.032 277.18C604.342 262.11 612.142 244.27 615.243 224.88C615.642 222.39 615.963 219.87 616.203 217.32C616.563 213.59 616.743 209.81 616.743 205.99C616.743 178.04 607.063 152.31 590.862 131.98Z"
            fill="#E6E6E6"
          />
          <path
            id="Vector_25"
            d="M460.793 211.649C452.711 204.025 447.076 193.92 452.715 172.378C453.135 170.774 452.01 169.053 450.923 167.801C449.836 166.548 447.787 165.967 446.498 167.008C447.416 164.304 460 154.644 460.919 151.94C459.846 153.363 451.663 156.413 451.008 154.756C450.352 153.1 450.927 151.238 451.503 149.552L454.938 139.501C455.802 136.973 459.21 139.994 460.919 137.94C463.98 134.261 460.486 130.382 465.16 129.351C480.456 125.974 496.672 122.958 511.532 127.913C518.024 130.078 523.927 133.674 529.766 137.24L540.452 143.766C546.581 147.509 553.239 151.95 554.919 158.933L543.699 154.923C546.198 160.245 548.719 165.632 549.862 171.399C551.005 177.166 547.914 198.881 544.919 203.94C542.287 208.384 540.425 196.448 539.559 201.54C540.55 191.537 536.047 181.162 528.065 175.053C526.428 173.8 524.561 172.595 523.833 170.666C523.021 168.515 523.835 165.929 522.727 163.915C521.348 161.41 517.748 161.172 515.063 162.155C512.379 163.137 510.032 164.985 507.251 165.647C499.879 167.402 493.172 160.432 485.612 159.907C478.41 159.408 471.967 165.046 468.972 171.615C465.977 178.184 457.693 176.762 456.919 183.94C456.144 191.118 467.454 205.323 462.919 210.94L460.793 211.649Z"
            fill="#2F2E41"
          />
          <path
            id="Vector_26"
            d="M455.122 278.61L451.773 276L450.993 275.4C450.243 274.81 449.253 274.61 448.322 274.84L444.402 275.82C443.993 275.92 443.552 275.8 443.253 275.5L438.852 271.1C438.552 270.8 438.432 270.36 438.532 269.95L439.512 266.03C439.753 265.1 439.543 264.12 438.953 263.36L435.743 259.23C435.662 259.13 435.572 259.03 435.483 258.94C434.262 257.72 432.293 257.72 431.072 258.94L429.223 260.79C427.892 262.11 427.313 264.1 427.963 265.94C429.622 270.66 432.322 274.95 435.872 278.48C437.132 279.74 438.483 280.9 439.912 281.94C442.512 283.82 445.372 285.32 448.412 286.39C450.262 287.04 452.243 286.46 453.563 285.14L455.412 283.28C456.743 281.96 456.602 279.77 455.122 278.61ZM454.063 281.94L452.213 283.79C451.342 284.65 450.122 284.97 449.043 284.59C446.493 283.7 444.082 282.48 441.852 280.96C440.193 279.85 438.642 278.56 437.223 277.13C433.882 273.8 431.332 269.76 429.762 265.31C429.382 264.23 429.703 263.01 430.563 262.14L432.422 260.29C432.453 260.25 432.493 260.22 432.532 260.19C433.063 259.78 433.822 259.87 434.233 260.4L437.443 264.53C437.672 264.82 437.753 265.21 437.662 265.57L436.682 269.49C436.412 270.55 436.733 271.68 437.503 272.45L441.902 276.85C442.672 277.63 443.802 277.94 444.862 277.67L448.782 276.69C449.142 276.6 449.532 276.68 449.822 276.91L449.872 276.95L453.953 280.12C453.993 280.15 454.032 280.19 454.063 280.22C454.543 280.7 454.543 281.46 454.063 281.94Z"
            fill="#CCCCCC"
          />
          <g id="Group_2">
            <path
              id="Vector_27"
              d="M494.16 269.572C494.654 269.572 495.055 269.972 495.055 270.467V272.256C495.055 276.209 498.259 279.414 502.213 279.414C506.166 279.414 509.371 276.209 509.371 272.256V270.467C509.371 269.972 509.772 269.572 510.266 269.572C510.76 269.572 511.161 269.972 511.161 270.467V272.256C511.161 276.851 507.68 280.7 503.108 281.159V284.783H508.476C508.97 284.783 509.371 285.184 509.371 285.678C509.371 286.172 508.97 286.572 508.476 286.572H495.949C495.455 286.572 495.055 286.172 495.055 285.678C495.055 285.184 495.455 284.783 495.949 284.783H501.318V281.159C496.746 280.7 493.265 276.851 493.265 272.256V270.467C493.265 269.972 493.666 269.572 494.16 269.572Z"
              fill="#CCCCCC"
            />
            <path
              id="Vector_28"
              d="M502.212 257.94C499.252 257.94 496.842 260.34 496.842 263.31V272.26C496.842 275.22 499.252 277.62 502.212 277.62C505.182 277.62 507.582 275.22 507.582 272.26V263.31C507.582 260.34 505.182 257.94 502.212 257.94ZM505.792 272.26C505.792 274.23 504.192 275.84 502.212 275.84C500.232 275.84 498.632 274.23 498.632 272.26V263.31C498.632 261.33 500.232 259.73 502.212 259.73C504.192 259.73 505.792 261.33 505.792 263.31V272.26Z"
              fill="#CCCCCC"
            />
          </g>
          <g id="Group_3">
            <path
              id="Vector_29"
              d="M564.142 258.29C561.332 257.34 558.282 258.84 557.332 261.65L558.842 263.16C558.942 261.26 560.522 259.77 562.432 259.78C564.402 259.79 566.002 261.4 565.992 263.37V270.31L567.782 272.1V263.37C567.782 261.08 566.312 259.03 564.142 258.29ZM568.682 284.85H563.312V281.22C564.882 281.07 566.382 280.5 567.662 279.57L566.372 278.28C565.242 279.04 563.882 279.48 562.412 279.48C558.462 279.48 555.252 276.27 555.252 272.32V270.53C555.252 270.37 555.212 270.21 555.122 270.08C554.972 269.82 554.692 269.64 554.362 269.64C554.202 269.64 554.052 269.68 553.922 269.76C553.652 269.91 553.462 270.2 553.462 270.53V272.32C553.462 276.91 556.952 280.76 561.522 281.22V284.85H556.152C555.652 284.85 555.252 285.25 555.252 285.74C555.252 286.24 555.652 286.64 556.152 286.64H568.682C569.172 286.64 569.572 286.24 569.572 285.74C569.572 285.25 569.172 284.85 568.682 284.85ZM570.472 269.64C569.972 269.64 569.572 270.04 569.572 270.53V272.32C569.572 272.8 569.522 273.28 569.432 273.75L569.522 273.84L570.882 275.21C570.982 274.9 571.072 274.59 571.142 274.27C571.292 273.64 571.362 272.99 571.362 272.32V270.53C571.362 270.04 570.962 269.64 570.472 269.64Z"
              fill="#CCCCCC"
            />
            <path
              id="Vector_30"
              d="M566.102 272.95L552.312 259.16L551.042 260.43L562.662 272.05L572.522 281.9L573.782 280.63L566.102 272.95ZM563.732 275.65C561.902 276.38 559.822 275.48 559.092 273.64C558.922 273.22 558.832 272.77 558.832 272.32V270.75L557.042 268.96V272.32C557.042 275.28 559.452 277.69 562.412 277.69C563.342 277.69 564.262 277.45 565.072 276.99L563.732 275.65Z"
              fill="#CCCCCC"
            />
          </g>
        </g>
      </transition>
    </g>
    <defs>
      <clipPath id="clip0_3_4812">
        <rect width="985.56" height="656.27" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
<script setup>
import gsap from "gsap";
const beforeEnter = (el) => {
  el.style.opacity = 0;
  el.style.transform = "translateX(-100%)";
};
const enter = (el) => {
  gsap.to(el, {
    opacity: 1,
    x: 0,
    duration: 2,
    ease: "elastic.out(1, 0.9)",
    delay: el.dataset.index * 0.12,
  });
};
</script>
<style scoped>
svg {
  width: 100%;
  aspect-ratio: 1/1;
}
/* #personOnline {
  animation: breathing 2s infinite;
} */
@keyframes breathing {
  0%,
  100% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 0.9;
    transform: scale(0.99);
  }
}
@keyframes hand {
  0%,
  100% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(1deg);
  }
}
@media screen and (max-width: 1300px) {
  svg {
    height: 300px;
  }
}
</style>
