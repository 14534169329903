<template>
  <section-badge>
    <template #textContent>
      <div class="contactPage">
        <form class="form" ref="form" @submit.prevent="sendEmail">
          <div class="form__container">
            <div class="input__group invisible" ref="ig1">
              <label for="from_name"
                >Imię i nazwisko <span class="invalid__text">*</span></label
              >
              <input
                required
                :class="{ invalid: nameValidity === 'invalid' }"
                v-model.trim="from_name"
                type="text"
                name="from_name"
                placeholder="Wprowadź imię i nazwisko..."
                @blur="validateInputName"
              />
              <p v-if="nameValidity === 'invalid'">
                to pole nie może być puste
              </p>
            </div>
            <div class="input__group" ref="ig1">
              <label for="from_name"
                >Imię i nazwisko <span class="invalid__text">*</span></label
              >
              <input
                required
                :class="{ invalid: nameValidity === 'invalid' }"
                v-model.trim="from_name"
                type="text"
                name="from_name"
                placeholder="Wprowadź imię i nazwisko..."
                @blur="validateInputName"
              />
              <p v-if="nameValidity === 'invalid'">
                to pole nie może być puste
              </p>
            </div>
            <div class="input__group" ref="ig2">
              <label for="from_name">Firma</label>
              <input
                v-model="company"
                type="text"
                name="company"
                placeholder="Wprowadź nazwę firmy..."
              />
            </div>
            <div class="input__group" ref="ig3">
              <label for="from_name"
                >Email <span class="invalid__text">*</span></label
              >
              <input
                required
                :class="{ invalid: mailValidity === 'invalid' }"
                v-model="from_email"
                type="from_email"
                name="from_email"
                placeholder="Wprowadź email..."
                @blur="validateInputMail"
              />
              <p v-if="mailValidity === 'invalid'">
                to pole nie może być puste
              </p>
            </div>
            <div class="input__group" ref="ig4">
              <label for="from_name"
                >Opis <span class="invalid__text">*</span></label
              >
              <textarea
                required
                :class="{ invalid: contentValidity === 'invalid' }"
                v-model="mailContent"
                name="mailContent"
                placeholder="Wprowadź treść..."
                @blur="validateInputContent"
              ></textarea>
              <p v-if="contentValidity === 'invalid'">
                to pole nie może być puste
              </p>
            </div>
            <div class="input__group__CheckBox" ref="ig5">
              <input
                class="checkbox__input"
                type="checkbox"
                name="myCheckboxName"
                required
              />
              <h1>
                * Wyrażam dobrowolną zgodę na przetwarzanie przez NiCode -
                Nikodem Wicon z siedzibą w Wejherowie przy ulicy Św. Jana 7c/38,
                moich danych osobowych zawartych w tym formularzu w celu i
                zakresie niezbędnym do odpowiedzi na to pytanie. Potwierdzam
                zapoznanie się z
                <a href="Rodo.docx" download>polityką prywatności.</a>
              </h1>
            </div>
            <div class="input__group">
              <button ref="ig6" type="submit" value="Send" required>
                Wyślij
              </button>
            </div>
          </div>
          <div class="loading__container">
            <the-loading
              v-if="waiting"
              :color="'#FCAF30'"
              :color2="'rgb(252, 175, 48)'"
            ></the-loading>
          </div>
        </form>
        <div class="icons__container">
          <bounce-icon
            ><a target="blank" href="https://www.facebook.com/NicodeWebDev"
              ><img
                class="invisible"
                ref="icon"
                src="../../public/Icons/fb.png"
                alt="" /></a
          ></bounce-icon>
          <bounce-icon
            ><a target="blank" href="https://www.facebook.com/NicodeWebDev"
              ><img ref="icon1" src="../../public/Icons/fb.png" alt="" /></a
          ></bounce-icon>
          <bounce-icon
            ><a target="blank" href="https://github.com/Nixon4056"
              ><img ref="icon2" src="../../public/Icons/gh.png" alt="" /></a
          ></bounce-icon>
          <bounce-icon
            ><a href="tel:+48696787013"
              ><img ref="icon3" src="../../public/Icons/phone.png" alt="" /></a
          ></bounce-icon>
          <bounce-icon
            ><a href="mailto:biuro@nicode.pl"
              ><img ref="icon4" src="../../public/Icons/mail.png" alt="" /></a
          ></bounce-icon>
        </div>
      </div>
    </template>
  </section-badge>
</template>
<script>
import emailjs from "@emailjs/browser";
import { gsap } from "gsap";
import { ref, onMounted } from "vue";
export default {
  data() {
    return {
      from_name: null,
      company: null,
      from_email: null,
      mailContent: null,
      sendValidity: "",
      waiting: false,
      nameValidity: "pending",
      mailValidity: "pending",
      contentValidity: "pending",
    };
  },
  methods: {
    cleanInputs() {
      this.from_name = "";
      this.company = "";
      this.from_email = "";
      this.mailContent = "";
    },
    sendEmail() {
      this.waiting = true;
      this.cleanInputs();

      emailjs
        .sendForm(
          "Nicode",
          "template_k3nl7xv",
          this.$refs.form,
          "d_4z_Zpr1-3s9lpTd",
          {
            company: this.company,
            from_name: this.from_name,
            from_email: this.from_email,
            emailContent: this.emailContent,
          }
        )
        .then(
          (result) => {
            console.log("SUCCESS!", result.text);
            this.$emit("send-validity", "success");
            this.sendValidity = "success";
            this.waiting = false;
          },
          (error) => {
            console.log("FAILED...", error.text);
            this.$emit("send-validity", "failed");
            this.sendValidity = "failed";
            this.waiting = false;
          }
        );
    },
    validateInputName() {
      if (this.from_name === "") {
        this.nameValidity = "invalid";
      } else {
        this.nameValidity = "valid";
      }
    },
    validateInputMail() {
      if (this.from_email === "") {
        this.mailValidity = "invalid";
      } else {
        this.mailValidity = "valid";
      }
    },
    validateInputContent() {
      if (this.mailContent === "") {
        this.contentValidity = "invalid";
      } else {
        this.contentValidity = "valid";
      }
    },
  },
  setup() {
    const icons = [];
    const icon = ref();
    const icon1 = ref();
    const icon2 = ref();
    const icon3 = ref();
    const icon4 = ref();

    const inputGroups = [];
    const ig = ref();
    const ig1 = ref();
    const ig2 = ref();
    const ig3 = ref();
    const ig4 = ref();
    const ig5 = ref();
    const ig6 = ref();

    onMounted(() => {
      icons.push(ref(icon));
      icons.push(ref(icon1));
      icons.push(ref(icon2));
      icons.push(ref(icon3));
      icons.push(ref(icon4));
      icons.forEach((icon, index) => {
        gsap.fromTo(
          icon.value,
          { yPercent: 300, opacity: 0, rotate: 20, scale: 0.8 },
          {
            yPercent: 0,
            opacity: 1,
            rotate: 0,
            scale: 1,
            duration: 2,
            delay: 1 / index,
            ease: "elastic.out(1, 0.8)",
          }
        );
      });
      inputGroups.push(ref(ig));
      inputGroups.push(ref(ig1));
      inputGroups.push(ref(ig2));
      inputGroups.push(ref(ig3));
      inputGroups.push(ref(ig4));
      inputGroups.push(ref(ig5));
      inputGroups.push(ref(ig6));

      inputGroups.forEach((ig, index) => {
        gsap.fromTo(
          ig.value,
          { xPercent: -200, opacity: 0, rotate: 5, scaleX: 0.8 },
          {
            xPercent: 0,
            opacity: 1,
            rotate: 0,
            scaleX: 1,
            duration: 2,
            delay: 1 / index,
            ease: "elastic.out(1, 0.8)",
          }
        );
      });
    });

    return {
      icon,
      icon1,
      icon2,
      icon3,
      icon4,
      ig,
      ig1,
      ig2,
      ig3,
      ig4,
      ig5,
      ig6,
    };
  },
};
</script>
<style scoped>
.contactPage {
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100vh;
}
.invisible {
  display: none;
}
.icons__container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
}
.icons__container img {
  cursor: pointer;
}
.contact_header {
  font-size: 3rem;
  line-height: 3.5rem;
  max-width: 100% !important;
}
.form {
  padding: 3rem;
  align-self: left;
  z-index: 1;
  width: 50%;
  max-width: 700px;
}
.input__group {
  position: relative;
}
.input__group p {
  font-size: 0.7rem;
  transition: 0.3s all;
  padding: 0.2rem 0.5rem;
  color: red;
}
.invalid {
  border-bottom: 1px red solid;
}
.invalid__text {
  color: red;
}
.input__group,
h1 {
  margin: 1rem 1rem;
}
input,
textarea {
  border: none;
  border-bottom: 1px #fca311 solid;
  padding: 0 0.5rem;
  transition: 0.3s all;
  background-color: transparent;
}
input:focus,
textarea:focus {
  outline: none;
  border-bottom: 1px #fca21157 solid;
  transform: translateX(1.5%);
}
input,
textarea,
button {
  height: 2.2rem;
  width: 100%;
  color: white;
}
textarea {
  width: 100%;
  max-width: 900px;
  max-height: 300px;
  min-height: 4rem;
  padding: 0.5rem 0.5rem;
}
button {
  background-color: #fca311;
  border: none;
  color: #e5e5e5;
  font-size: 1rem;
  cursor: pointer;
  height: 3rem;
  transition: 0.3s all;
  border-radius: 6px;
}
button:hover {
  background-color: #fca21183;
}
label {
  margin-left: 0.5rem;
  font-size: 1rem;
  font-weight: 500;
}
.input__group__CheckBox {
  display: flex;
  align-items: flex-start;
  justify-content: left;
  margin: 1rem 1rem;
}
.input__group__CheckBox a {
  color: #fca311;
  text-decoration: underline;
}
.input__group__CheckBox input {
  width: unset;
  height: unset;
  box-shadow: unset;
  cursor: pointer;
}
.input__group__CheckBox input {
  background-color: #fca311;
}
.input__group__CheckBox h1 {
  margin: 0 1rem;
  color: rgb(179, 179, 179);
  font-size: 0.6rem;
  line-height: 1.2rem;
  max-width: 100% !important;
}
.loading__container {
  margin: 0;
  align-self: center;
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
}
@media screen and (max-width: 1200px) {
  .contact_header {
    font-size: 2rem;
    line-height: 2.5rem;
  }
  .form {
    padding: 0rem;
    align-self: left;
    z-index: 1;
    width: 100%;
  }
}
@media screen and (max-width: 900px) {
  .contact_header {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  .contactPage {
    flex-direction: column;
    justify-content: center;
  }
  .icons__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin: 5% 0;
    gap: 10%;
    width: 100%;
  }
  .icons__container img {
    width: 50px;
  }
  input,
  textarea {
    font-size: 0.7rem;
  }
  button {
    font-size: 1rem;
  }
  label {
    font-size: 0.8rem;
  }
  .input__group,
  h1 {
    margin: 0.5rem 0rem;
  }
  .input__group__CheckBox h1 {
    margin: 0 1rem;
    color: rgb(179, 179, 179);
    font-size: 0.5rem;
    line-height: 1rem;
    max-width: 100% !important;
  }
}
</style>
