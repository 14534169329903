<template>
  <router-link to="/"
    ><div class="logo"><nicode-logo /></div
  ></router-link>
  <nav>
    <div :class="{ active: navIsActive }" class="navigation">
      <ul>
        <router-link @click="navHandler" to="/"
          ><li class="homeBtn"><img src="home.svg" alt="" /></li
        ></router-link>
        <router-link @click="navHandler" to="/services"
          ><li>Proces</li></router-link
        >
        <router-link @click="navHandler" to="/portfolio"
          ><li>Portfolio</li></router-link
        >
        <router-link @click="navHandler" to="/contact"
          ><li>Kontakt</li></router-link
        >
      </ul>
    </div>
    <div :class="{ active: navIsActive }" @click="navHandler" class="burger">
      <div class="line line1"></div>
      <div class="line line2"></div>
      <div class="line line3"></div>
    </div>
  </nav>
</template>

<script setup>
import { gsap } from "gsap";
import { ref } from "vue";
let navIsActive = ref(false);
const tl1 = gsap.timeline();
const tl2 = gsap.timeline();
const tl3 = gsap.timeline();

const navHandler = () => {
  navIsActive.value = !navIsActive.value;
  if (navIsActive.value) {
    tl1
      .to(".line1", { y: 15, duration: 0.1, width: "100%" })
      .to(".line1", { rotate: 45, duration: 0.1, delay: 0.3 });
    tl2
      .to(".line3", { y: -14, duration: 0.1, width: "100%" })
      .to(".line3", { rotate: 135, duration: 0.1, delay: 0.3 });
    tl3.to(".line2", { scaleX: 0.5, duration: 0.1, opacity: 0 });
  } else {
    tl1
      .to(".line1", { rotate: 0, duration: 0.1 })
      .to(".line1", { y: 0, duration: 0.1, delay: 0.4, width: "50%" });
    tl2
      .to(".line3", { rotate: 0, duration: 0.1 })
      .to(".line3", { y: 0, duration: 0.1, delay: 0.4, width: "50%" });
    tl3.to(".line2", { scaleX: 1, duration: 0.3, opacity: 1 });
  }
};
</script>

<style scoped>
.logo {
  cursor: pointer;
}
.logo svg {
  width: 200px;
  max-height: 40px;
}
nav {
  border-radius: 15px;
  background-color: #0e182c;
  overflow: hidden;
  transition: 0.3s all;
  filter: drop-shadow(7px 7px 10px rgba(0, 0, 0, 0.25));
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.navigation {
  max-width: 0px;
  transition: max-width 0.5s ease-out;
}
.navigation.active {
  max-width: 1000px;
}
nav ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  padding: 0 2rem;
  list-style: none;
  transition: 0.3s all;
}
nav:hover {
  border-radius: 5px;
}
nav ul li {
  display: flex;
  align-items: center;
  padding: 0.3rem 1rem;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 2rem;
  transition: 0.3s all;
}
.homeBtn {
  padding: 1rem !important;
}
nav ul li img {
  width: 1.3rem;
}
nav ul li:hover {
  background-color: white;
  color: #fca311;
}
.burger {
  border-radius: 15px;
  background-color: #0e182c;
  width: 65px;
  height: 65px;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  padding: 0.7rem;
  cursor: pointer;
  transition: 0.3s all;
  align-self: flex-end;
}
.burger:hover {
  background-color: white;
  border-radius: 5px;
}
.line {
  background-color: #fca311;
  height: 4px;
  width: 100%;
  border-radius: 10px;
  transition: 0.5s all;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.25);
}
.line1,
.line3 {
  width: 50%;
}
.line3 {
  align-self: flex-end;
}
@media screen and (max-width: 1100px) {
  nav {
    border-radius: 10px !important;
    background-color: #0e182c;
    overflow: hidden;
    transition: 0.3s all;
    filter: drop-shadow(7px 7px 10px rgba(0, 0, 0, 0.25));
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column-reverse;
  }
  .navigation {
    transition: all 0.5s ease-out;
    width: 65px;
    max-height: 0px;
  }
  .navigation.active {
    width: 100%;
    max-height: 700px;
  }
  nav ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    gap: 2rem;
    padding: 2rem 2rem;
    list-style: none;
    transition: 0.3s all;
  }
}
@media screen and (max-width: 400px) {
  .logo svg {
    width: 170px;
    max-height: 40px;
  }
  .burger {
    transform: scale(0.8);
  }
}
</style>
