<template>
  <div>
    <svg
      class="cookie"
      width="97"
      height="92"
      viewBox="0 0 97 92"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="cookie">
        <g id="XMLID 710">
          <g id="cookieMain">
            <g id="XMLID 718">
              <path
                id="Vector"
                d="M57.9956 47.528C52.8588 42.0106 47.9756 36.303 43.8534 29.9612C40.9362 25.3951 38.336 20.2582 35.2285 17.0873C32.4382 14.1701 30.6624 11.0626 29.7746 7.89172C23.5596 9.66743 19.2472 11.1895 13.6664 17.0239C-25.2088 57.1041 31.1064 119 74.3575 78.2857C77.5284 75.3051 76.3868 71.6903 78.2894 68.4559C72.2647 61.4799 64.3374 54.3137 57.9956 47.528Z"
                fill="#DE814B"
              />
            </g>
            <g id="XMLID 717">
              <path
                id="Vector_2"
                d="M74.3604 78.2853C34.2802 110.819 -21.5276 59.1331 13.6694 16.9601C14.2401 16.3259 14.8743 15.6283 15.5085 14.9941C23.5626 7.00345 32.5045 3.26178 41.2562 2.69102C42.1441 2.6276 42.8417 3.3252 42.9051 4.14964C43.0319 9.34992 46.3931 12.8379 51.3397 13.5355C52.1007 13.6623 52.5446 14.3599 52.3544 15.121C51.847 16.7698 51.6568 18.5455 51.6568 20.3846C51.847 29.7071 59.584 37.3807 68.9065 37.4441C70.809 37.4441 72.6481 37.1904 74.4238 36.6197C75.1214 36.366 75.8824 36.8099 76.0093 37.5709C77.1508 43.7859 82.795 42.6444 85.4586 41.7565C86.2196 41.5029 87.044 41.9468 87.2343 42.7078C92.4345 61.6698 74.3604 78.2853 74.3604 78.2853Z"
                fill="#F6B059"
              />
            </g>
          </g>
          <g id="XMLID 715">
            <path
              id="Vector_3"
              d="M47.1513 38.2055C47.1513 42.8985 43.3462 46.0694 38.6533 46.0694C33.9603 46.0694 30.1553 42.8985 30.1553 38.2055C30.1553 33.5126 33.9603 29.7075 38.6533 29.7075C43.3462 29.7075 47.1513 33.4492 47.1513 38.2055Z"
              fill="#6D420D"
            />
          </g>
          <g id="XMLID 714">
            <path
              id="Vector_4"
              d="M73.8506 58.6897C73.8506 62.3045 70.9334 65.2218 67.3186 65.2218C63.7038 65.2218 61.2305 63.0656 61.2305 59.4507C61.2305 55.8359 63.7038 52.0942 67.3186 52.0942C70.9334 52.0942 73.8506 55.0749 73.8506 58.6897Z"
              fill="#6D420D"
            />
          </g>
          <g id="XMLID 713">
            <path
              id="Vector_5"
              d="M25.021 50.699C25.021 53.4894 22.9916 55.0748 20.2013 55.0748C17.4109 55.0748 15.001 53.4894 15.001 50.699C15.001 47.9086 17.2206 45.689 20.011 45.689C22.8014 45.689 25.021 47.9086 25.021 50.699Z"
              fill="#6D420D"
            />
          </g>
          <g id="XMLID 712">
            <path
              id="Vector_6"
              d="M40.365 67.6936C40.365 68.962 39.3503 69.9767 38.082 69.9767C36.8136 69.9767 35.7989 68.962 35.7989 67.6936C35.7989 66.4253 36.8136 65.4106 38.082 65.4106C39.3503 65.4106 40.365 66.4253 40.365 67.6936Z"
              fill="#6D420D"
            />
          </g>
          <g id="XMLID 711">
            <path
              id="Vector_7"
              d="M24.8912 23.8732C26.2922 23.8732 27.4279 22.7375 27.4279 21.3365C27.4279 19.9355 26.2922 18.7998 24.8912 18.7998C23.4902 18.7998 22.3545 19.9355 22.3545 21.3365C22.3545 22.7375 23.4902 23.8732 24.8912 23.8732Z"
              fill="#6D420D"
            />
          </g>
        </g>
        <g id="crumbs">
          <g id="crumbs1">
            <g id="delsss">
              <g id="XMLID 709">
                <path
                  id="Vector_8"
                  d="M70.7395 6.11551C70.9931 9.15958 68.3296 11.6329 64.7782 11.6329C61.2268 11.6329 58.4998 8.9059 58.69 5.54475C58.8803 2.18359 61.5439 -0.289706 64.6513 0.0273843C67.7588 0.344474 70.4858 3.00803 70.7395 6.11551Z"
                  fill="#F6B059"
                />
              </g>
            </g>
            <g id="XMLID 706">
              <g id="XMLID 707">
                <path
                  id="Vector_9"
                  d="M66.1764 10.4277C67.5716 10.4277 68.8399 10.0472 69.8546 9.34964C68.7765 10.7448 67.0008 11.6327 64.8446 11.6327C61.2932 11.6327 58.5662 8.90571 58.7565 5.60797C58.8833 3.5786 59.898 1.86631 61.3566 0.915039C60.659 1.86631 60.1517 3.07125 60.0882 4.40303C59.9614 7.63735 62.6884 10.3643 66.1764 10.4277Z"
                  fill="#DE814B"
                />
              </g>
            </g>
          </g>
          <g id="crumbs3">
            <g id="XMLID 703">
              <g id="XMLID 704">
                <path
                  id="Vector_10"
                  d="M70.4267 23.3643C70.2999 25.3937 68.5876 27.1059 66.6217 27.2962C64.6557 27.423 63.0703 25.7742 63.1337 23.618C63.1337 21.4617 64.846 19.686 66.9388 19.686C69.0315 19.686 70.5536 21.3349 70.4267 23.3643Z"
                  fill="#F6B059"
                />
              </g>
            </g>
            <g id="XMLID 701">
              <g id="XMLID 702">
                <path
                  id="Vector_11"
                  d="M67.3798 26.4081C68.1408 26.3447 68.9018 26.0276 69.536 25.5836C68.8384 26.5349 67.7603 27.1691 66.5554 27.2959C64.5894 27.4228 63.0674 25.7739 63.0674 23.6177C63.0674 22.3493 63.7016 21.1444 64.6528 20.4468C64.2089 21.081 63.8918 21.9054 63.8918 22.7298C63.8284 24.886 65.4139 26.5349 67.3798 26.4081Z"
                  fill="#DE814B"
                />
              </g>
            </g>
          </g>
          <g id="crumbs2">
            <g id="XMLID 698">
              <g id="XMLID 699">
                <path
                  id="Vector_12"
                  d="M96.3602 24.9512C96.17 27.361 94.204 29.3904 91.8575 29.5173C89.5745 29.6441 87.7354 27.7415 87.7354 25.2048C87.7988 22.6681 89.7647 20.6387 92.238 20.6387C94.7114 20.6387 96.5505 22.6047 96.3602 24.9512Z"
                  fill="#F6B059"
                />
              </g>
            </g>
            <g id="XMLID 696">
              <g id="XMLID 697">
                <path
                  id="Vector_13"
                  d="M92.8088 28.5663C93.7601 28.5029 94.6479 28.1224 95.3455 27.5516C94.5211 28.6932 93.2527 29.4542 91.8575 29.5176C89.5745 29.6444 87.7354 27.7419 87.7354 25.2052C87.7354 23.6831 88.4964 22.3514 89.5745 21.4635C89.0037 22.2245 88.6866 23.1758 88.6866 24.1905C88.6866 26.7906 90.5258 28.6932 92.8088 28.5663Z"
                  fill="#DE814B"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </div>
</template>

<script></script>

<style scoped>
div {
  align-self: center;
} /* 
  #crumbs{
    transition: 0.3s transform;
  }
  #crumbs1{
    animation: crumbs 0.3s forwards;
  }
  #crumbs2{
    animation: crumbs 0.9s forwards;
  }
  #crumbs3{
    animation: crumbs 1.5s forwards;
  }
  @keyframes crumbs {
    0%{
      transform: translateX(-10px) translateY(10px);
    }
    100%{
      transform: translateX(0px) translateY(0px);
    }
  }
  .cookie{
    animation: rotate 0.4s ease-out;
  }
  @keyframes rotate {
    0%{
      transform: rotate(20deg) translateX(-100px);
      opacity: 0;
    }
    100%{
      transform: rotate(0deg) translateX(0px);
      opacity: 1;
    }
  } */
</style>
